import { all } from 'redux-saga/effects'

import brandSagas from 'redux/sagas/brandSagas'
import cartSagas from 'redux/sagas/cartSagas'
import errorsSagas from 'redux/sagas/errorsSagas'
import navigationSagas from 'redux/sagas/navigationSagas'
import orderAtTableSagas from 'redux/sagas/orderAtTableSagas'
import orderSagas from 'redux/sagas/orderSagas'
import productsSagas from 'redux/sagas/productsSagas'
import storesSagas from 'redux/sagas/storesSagas'

export default function * rootSaga() {
  yield all([
    brandSagas(),
    cartSagas(),
    errorsSagas(),
    navigationSagas(),
    orderAtTableSagas(),
    orderSagas(),
    productsSagas(),
    storesSagas(),
  ])
}
