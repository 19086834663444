import React, { memo } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'

import ProductItem from './ProductItem'

import translator from 'utility/translator'

const useStyles = makeStyles((theme) => ({
  noProductsMessage: {
    margin: 30,
    display: 'flex',
    justifyContent: 'center',
  },
  wrap: {
  },
  headers: {
    backgroundColor: theme.palette.primary.contrastText,
    top: 0,
    position: 'sticky',
    zIndex: 1,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '1px 1px 3px rgba(0,0,0,.4)',
  },
  headersBorderTop: {
    top: 0,
    position: 'sticky',
    zIndex: 1,
    borderTop: '5px solid ' + theme.palette.background.default,
  },
  tracks: {
    minHeight: 50,
    display: 'flex',
    overflowY: 'hidden',
    overflow: 'auto',
    scrollSnapType: 'x mandatory',
    color: theme.palette.primary.main,
  },
  productsContainer: {
    padding: theme.spacing(4),
  },
  product: {
    cursor: 'pointer',
    padding: theme.spacing(1, 4),
  },
}))

const DesktopProductsList = memo(({ props: { loading = [], filteredProductList = [], filteredCategoriesList = [] } }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.wrap}>
        {filteredCategoriesList.length > 0 ? filteredCategoriesList
          .map(category => (
            <React.Fragment key={category.categoryId}>
              <div className={classes.headers}>
                <Toolbar className={classes.tracks}>
                  <Typography variant='h5'>{category.name}</Typography>
                </Toolbar>
              </div>
              {filteredProductList[category.productGroupId] != null
                ? (!category.imagesEnabled
                    ? (
                      <List>
                        {filteredProductList[category.productGroupId]
                          .map(product => {
                            return (
                              <React.Fragment key={product.productId}>
                                <ListItem className={classes.product}>
                                  <ProductItem props={{ product, imagesEnabled: category.imagesEnabled, mobile: false }} />
                                </ListItem>
                                <Divider />
                              </React.Fragment>
                            )
                          })}
                      </List>
                      ) : (
                        <Grid container spacing={2} className={classes.productsContainer}>
                          {filteredProductList[category.productGroupId]
                            .map(product => {
                              return (
                                <Grid xs={12} sm={6} lg={4} item key={product.productId} className={classes.product}>
                                  <ProductItem props={{ product, imagesEnabled: category.imagesEnabled, mobile: false }} />
                                </Grid>
                              )
                            })}
                        </Grid>
                      )) : <NoProductsMessage />}
            </React.Fragment>
          ))
          : (loading.length === 0 ? <NoProductsMessage /> : null)}
      </div>
    </>
  )
})

const NoProductsMessage = () => {
  const classes = useStyles()
  return (
    <div className={classes.noProductsMessage}>
      <Typography variant='h6'>
        {translator.fromLabel('productSelector_noProducts_message')}
      </Typography>
    </div>
  )
}

export default DesktopProductsList
