import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Modal from 'components/modal/Modal'
import translator from 'utility/translator'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    padding: 10,
    textAlign: 'center',
  },
  button: {
    margin: 10,
  },
  phone: {
    color: theme.palette.error.main,
    textAlign: 'center',
  },
  divider: {
    width: '90%',
    height: 1,
    backgroundColor: theme.palette.secondary.contrastText,
    margin: '20px 5%',
  },
  zipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  zipButton: {
    width: '40%',
    margin: 10,
  },
}))

const FirstModal = ({ props: { loading = [], multipleStores = false, brand = {}, store, goToStoresSelector = () => {}, setModal = () => {}, onSelect = () => {} } = {} }) => {
  const classes = useStyles()

  return (
    <Modal>
      <div className={classes.container}>
        {store?.services
          ? <Typography variant='h4' className={classes.title}>{translator.fromLabel('welcomeModal_title')}</Typography>
          : null}
        {store?.services?.takeAway?.active
          ? (
            <Button
              data-cy='welcomeModal_takeAway_button'
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={() => onSelect({ modality: 'takeAway' })}
            >
              {translator.fromLabel('welcomeModal_takeAway_button')}
            </Button>
            ) : null}
        {store?.services?.delivery?.active
          ? (
            <Button
              data-cy='welcomeModal_delivery_button'
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={() => setModal('deliveryModal')}
            >
              {translator.fromLabel('welcomeModal_delivery_button')}
            </Button>
            ) : null}
        {brand?.orderAtTable && store?.orderAtTable?.enabled
          ? (
            <Button
              data-cy='welcomeModal_orderAtTable_button'
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={() => setModal('orderAtTable')}
            >
              {translator.fromLabel('welcomeModal_orderAtTable_button')}
            </Button>
            ) : null}
        <div className={classes.divider} />
        <Typography variant='h6' className={classes.title}>{translator.fromLabel('welcomeModal_intollerances_title')}</Typography>
        <Typography variant='body1'>{translator.fromLabel('welcomeModal_text')}</Typography>
        <Typography variant='h6' className={classes.phone}>{store?.storeProperties?.phone}</Typography>
        <div className={classes.divider} />
        {multipleStores
          ? (
            <Button
              data-cy='welcomeModal_zipCodeError_button'
              className={classes.button}
              variant='outlined'
              color='primary'
              onClick={goToStoresSelector}
              disabled={loading.length > 0}
            >
              {translator.fromLabel('welcomeModal_zipCodeError_button')}
            </Button>
            ) : null}
      </div>
    </Modal>
  )
}

export default FirstModal
