import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import translator from 'utility/translator'

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  text: {
    textAlign: 'center',
  },
}))

const Home = () => {
  const classes = useStyles()

  return (
    <div className={classes.main}>
      <Typography variant='h4'>{translator.fromLabel('home_title')}</Typography>
      <Typography variant='h6' className={classes.text}>{translator.fromLabel('home_text')}</Typography>
    </div>
  )
}

export default Home
