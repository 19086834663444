export default {
  apiKey: 'AIzaSyCxPOPHgBDEP-fx54BIrRJYYROyWw5V7X4',
  authDomain: 'mytec-ecommerce.firebaseapp.com',
  databaseUrl: 'https://mytec-ecommerce.firebaseio.com',
  projectId: 'mytec-ecommerce',
  storiageBucket: 'mytec-ecommerce.appspot.com',
  messagingSenderApp: '336128723821',
  appId: '1:336128723821:web:7d930cddd1bffd9f026864',
  measurementId: 'G-VE5JQ9Q8VF',
}
