import React, { memo } from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import translator from 'utility/translator'
import genericProduct from 'assets/img/genericProduct.png'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const ProductItem = React.memo(({
  props: {
    complete = false,
    product, product: { productId = '', name = '', price = 0, description = '', variations = {} },
    savedVariations = [],
    imagesEnabled = false,
    mobile = true,
  },
}) => {
  const history = useHistory()
  const products = useSelector(state => state.products)
  const theme = useTheme()

  const shortDescrLength = theme.values.shortDescrLength || 40
  const followUpDescr = description.length > shortDescrLength ? ' ...' : ''
  const shortDescr = `${description.substring(0, shortDescrLength).split('\n')[0]}${followUpDescr}`

  const calculatedPrice = savedVariations
    .reduce((price, variationId) => price + Number(variations[variationId].price), price)

  const selectProduct = () => {
    if (!complete)
      history.push(`${history.location.pathname}/products?productId=${productId}`)
  }

  const productImageUrl = imagesEnabled && products[productId] != null && products[productId].productImageUrl != null
    ? products[productId].productImageUrl : genericProduct

  if (imagesEnabled)
    return <ProductItemImage props={{ mobile, productImageUrl, selectProduct, shortDescr, followUpDescr, calculatedPrice, product }} />
  else
    return <ProductItemList props={{ selectProduct, shortDescr, followUpDescr, calculatedPrice, product }} />
})

const useStylesProductItem = makeStyles((theme) => ({
  productContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
  },
  container02: {
    // flexDirection: mobile ? 'row' : 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    display: 'flex',
    marginTop: 10,
    width: '100%',
  },
  miniProductImage: {
    width: 100,
    height: 100,
    minHeight: 100,
    minWidth: 100,
    maxHeight: 100,
    maxWidth: 100,
    margin: '5px 0px 20px 0px',
    borderRadius: 4,
    position: 'relative',
    backgroundSize: 'cover',
    boxShadow: `0px 2px 1px -1px ${theme.palette.boxShadow.primary}, 0px 1px 1px 0px ${theme.palette.boxShadow.secondary}, 0px 1px 3px 0px ${theme.palette.boxShadow.tertiary}`,
  },
  miniPriceContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    position: 'absolute',
    left: -15,
    bottom: -15,
    borderRadius: '50%',
    padding: 5,
    boxShadow: '1px 1px 3px 1px rgba(0,0,0,.4)',
  },
  productProperties: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px 10px',
  },
  name: {
    textAlign: 'center',
  },
  shortDescr: {
    textAlign: 'center',
  },
  price: {
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const ProductItemImage = memo(({ props: { mobile, productImageUrl, selectProduct, shortDescr, followUpDescr, calculatedPrice, product: { productId, name, description } = {} } }) => {
  const classes = useStylesProductItem()

  if (mobile)
    return (
      <div style={{ flexDirection: 'row' }} data-cy={productId + '_productItem'} className={classes.productContainer} onClick={selectProduct}>
        <div style={{ flex: '1 1 1px', height: 1 }} />
        <div className={classes.miniProductImage} style={{ backgroundImage: `url(${productImageUrl})` }}>
          <div className={classes.miniPriceContainer}>
            <div className={classes.price}>
              <Typography variant='body1'>{`${translator.toPrice(calculatedPrice)}€`}</Typography>
            </div>
          </div>
        </div>
        <div style={{ flex: '1 1 1px', height: 1 }} />
        <div style={{ width: '50%' }} className={classes.productProperties}>
          <Typography variant='h5' className={classes.name}>{name}</Typography>
          <Typography variant='body1' className={classes.shortDescr}>
            {shortDescr}
          </Typography>
        </div>
        <div style={{ flex: '1 1 1px', height: 1 }} />
      </div>
    )
  else
    return (
      <div style={{ flexDirection: 'column' }} data-cy={productId + '_productItem'} className={classes.productContainer} onClick={selectProduct}>
        <div className={classes.miniProductImage} style={{ backgroundImage: `url(${productImageUrl})` }}>
          <div className={classes.miniPriceContainer}>
            <div className={classes.price}>
              <Typography variant='body1'>{`${translator.toPrice(calculatedPrice)}€`}</Typography>
            </div>
          </div>
        </div>
        <div className={classes.productProperties}>
          <Typography variant='h5' className={classes.name}>{name}</Typography>
          <Typography variant='body1' className={classes.shortDescr}>
            {shortDescr}
          </Typography>
        </div>
      </div>
    )
})

const useStylesListItem = makeStyles((theme) => ({
  productContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
  },
  miniPriceContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    borderRadius: '50%',
    boxShadow: '1px 1px 3px 1px rgba(0,0,0,.4)',
  },
  name: {
    textAlign: 'left',
  },
  shortDescr: {
    textAlign: 'left',
  },
  price: {
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productProperties: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
}))

const ProductItemList = memo(({ props: { mobile = true, selectProduct, shortDescr, followUpDescr, calculatedPrice, product: { productId, name, description } = {} } }) => {
  const classes = useStylesListItem()

  return (
    <div data-cy={productId + '_productItem'} className={classes.productContainer} onClick={selectProduct}>
      <div className={classes.productProperties}>
        <Typography variant='h5' className={classes.name}>{name}</Typography>
        <Typography variant='body1' className={classes.shortDescr}>
          {shortDescr}
        </Typography>
      </div>
      <div style={{ flex: '10 1 1px', height: 1 }} />
      <div className={classes.miniPriceContainer}>
        <div className={classes.price}>
          <Typography variant='body1'>{`${translator.toPrice(calculatedPrice)}€`}</Typography>
        </div>
      </div>
    </div>
  )
})

export default ProductItem
