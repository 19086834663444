import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'

import translator from 'utility/translator'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as actions from 'redux/actions'
import { Home, Check, HighlightOff, Timer, ThumbUpAlt } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    textAlign: 'center',
  },
  button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const WaitOrder = () => {
  const order = useSelector(state => state.order)
  const dispatch = useDispatch()
  const { brandId, storeId } = useParams()

  const cancelOrder = () => dispatch({
    type: actions.CANCEL_ORDER_AT_TABLE,
    payload: { brandId, storeId, orderId: order.orderId },
  })

  const stopWait = () => dispatch({ type: actions.STOP_WAIT_ORDER_AT_TABLE, payload: { brandId, storeId } })

  switch (order?.status) {
    case 'accepted':
      return <OrderAccepted props={{ order, stopWait }} />
    case 'rejected':
      return <OrderRejected props={{ order, stopWait }} />
    case 'pending':
      return <OrderPending props={{ order }} />
    default:
      return <OrderWaiting props={{ order, cancelOrder }} />
  }
}

const OrderAccepted = ({ props: { order: { orderId } = {}, stopWait = () => {} } = {} }) => {
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <div className={classes.title} style={{ color: 'green' }}>
        <Check fontSize='large' />
        <div style={{ width: 25 }} />
        <Typography variant='h4'>{translator.fromLabel('waitOrder_orderAccepted_title')}</Typography>
      </div>
      <Typography variant='h6'>{translator.fromLabel('waitOrder_orderAccepted_text')}</Typography>
      <Typography variant='h6'>{translator.fromLabel('waitOrder_orderId_label') + orderId}</Typography>
      <div className={classes.button}>
        <Tooltip title={translator.fromLabel('waitOrder_home_button')} placement='top'>
          <Fab
            color='primary'
            onClick={stopWait}
            className={classes.fab}
            aria-label='add'
          >
            <Home />
          </Fab>
        </Tooltip>
      </div>
    </div>
  )
}

const OrderRejected = ({ props: { order: { orderId } = {}, stopWait = () => {} } = {} }) => {
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <div className={classes.title} style={{ color: 'darkRed' }}>
        <HighlightOff fontSize='large' />
        <div style={{ width: 25 }} />
        <Typography variant='h4'>{translator.fromLabel('waitOrder_orderRejected_title')}</Typography>
      </div>
      <Typography variant='h6'>{translator.fromLabel('waitOrder_orderRejected_text')}</Typography>
      <Typography variant='h6'>{translator.fromLabel('waitOrder_orderId_label') + orderId}</Typography>
      <div className={classes.button}>
        <Tooltip title={translator.fromLabel('waitOrder_home_button')} placement='top'>
          <Fab
            color='primary'
            onClick={stopWait}
            className={classes.fab}
            aria-label='add'
          >
            <Home />
          </Fab>
        </Tooltip>
      </div>
    </div>
  )
}

const OrderPending = ({ props: { order: { orderId } = {} } = {} }) => {
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <div className={classes.title}>
        <ThumbUpAlt fontSize='large' />
        <div style={{ width: 25 }} />
        <Typography variant='h4'>{translator.fromLabel('waitOrder_orderPending_title')}</Typography>
      </div>
      <Typography variant='h6'>{translator.fromLabel('waitOrder_orderPending_text')}</Typography>
      <Typography variant='h6'>{translator.fromLabel('waitOrder_orderId_label') + orderId}</Typography>
    </div>
  )
}

const OrderWaiting = ({ props: { order: { orderId } = {}, cancelOrder = () => {} } = {} }) => {
  const [timer, setTimer] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => setTimer(true), 60000)
  }, [])

  const classes = useStyles()
  return (
    <div className={classes.main}>
      <div className={classes.title}>
        <Timer fontSize='large' />
        <div style={{ width: 25 }} />
        <Typography variant='h4'>{translator.fromLabel('waitOrder_orderWaiting_title')}</Typography>
      </div>
      {timer
        ? (
          <>
            <Typography variant='h6'>{translator.fromLabel('waitOrder_orderTimeout_warning')}</Typography>
            <Button onClick={cancelOrder} variant='contained' color='primary'>{translator.fromLabel('waitOrder_cancelOrder_button')}</Button>
          </>
          ) : <Typography variant='h6'>{translator.fromLabel('waitOrder_orderWaiting_text')}</Typography>}
      <Typography variant='h6'>{translator.fromLabel('waitOrder_orderId_label') + orderId}</Typography>
    </div>
  )
}

export default WaitOrder
