import { put, select, takeEvery, all } from 'redux-saga/effects'
import * as actions from 'redux/actions'

import firebase from 'redux/firebase/firebase'
import { formatError, formatFirebaseError } from 'redux/format'

const orderAtTableRegEx = /^\?orderAtTable=[^&]+$/

export function * watchGetStores() {
  yield takeEvery(actions.GET_STORES, getStores)
}

export function * getStores({ payload: { brandId = '', storeId, search = '' } = {} }) {
  yield put({ type: actions.START_LOADING, payload: { action: 'getStores' } })
  try {
    const stores = yield firebase.getSubCollection({
      collectionId: 'Brands',
      docId: brandId,
      subCollectionId: 'Stores',
    })
    if (stores == null || stores.length === 0)
      throw formatError({ message: 'Stores not found', code: 201 })

    let storesList = stores
      .filter(store => store.storeId != null && store.enabled &&
        store.idDeliveryProduct != null && store.idDeliveryProduct !== '')
      .reduce((storeList, store) => ({ ...storeList, [store.storeId]: store }), {})

    storesList = yield getStoresFromSQL({ storesList })

    yield put({ type: actions.REDUCE_EDIT_STORES, payload: storesList })

    const brand = yield select(state => state.brand)

    if (storeId != null && search != null &&
      brand.orderAtTable === true && search.match(orderAtTableRegEx))
      yield put({ type: actions.START_ORDER_AT_TABLE, payload: { brandId, storeId, param: search } })
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
  yield put({ type: actions.STOP_LOADING, payload: { action: 'getStores' } })
}

function * getStoresFromSQL({ storesList }) {
  const storeIdList = Object.keys(storesList || {})
  if (storeIdList.length > 0) {
    const data = yield firebase.callFunction({ functionName: 'getStores', data: { storeIdList } })
    // eslint-disable-next-line
    if (data != null && data.error == null) {
      for (let storeProperties of data.results)
        if (storesList[storeProperties.id] != null)
          storesList[storeProperties.id] = { ...storesList[storeProperties.id], storeProperties }
    // eslint-disable-next-line
    } else {
      console.log(data)
    }
  }

  return storesList
}

export default function * storesSagas() {
  yield all([
    watchGetStores(),
  ])
}
