/* global localStorage */
import { put, takeEvery, all, select } from 'redux-saga/effects'
import * as actions from 'redux/actions'
import { history } from 'index'
import { matchPath } from 'react-router'

import uniqid from 'uniqid'

import { formatCartProduct, formatFirebaseError } from 'redux/format'

import { uniteDuplicateProducts, checkCart, getDeliveryPrice } from 'utility/pureFunctions'

function * watchGetCart() {
  yield takeEvery(actions.GET_CART, getCart)
}

function * getCart({ payload: { storeId, brandId, products } }) {
  try {
    let data = JSON.parse(localStorage.getItem('ecommerceBBCart'))
    if (data != null && data.cart != null && data.brandId === brandId && data.storeId === storeId) {
      const cart = checkCart({ cart: data.cart, products })
      yield saveCart({ cart })
    } else {
      yield put({ type: actions.CMD_NO_CART_SAVED })
    }
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
}

function * watchCreateCartProduct() {
  yield takeEvery(actions.CREATE_CART_PRODUCT, createCartProduct)
}

function * createCartProduct({ payload, payload: { cartProductId } }) {
  try {
    let cart = { ...yield select(state => state.cart) }
    cartProductId = cartProductId || uniqid()
    cart[cartProductId] = {
      cartProductId,
      ...(cart[cartProductId]),
      ...formatCartProduct(payload),
    }
    yield saveCart({ cart })
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
}

function * watchEditCartProduct() {
  yield takeEvery(actions.EDIT_CART_PRODUCT, editCartProduct)
}

function * editCartProduct({ payload: { cartProductId, change } }) {
  try {
    let cart = { ...yield select(state => state.cart) }
    cart[cartProductId] = { ...cart[cartProductId], ...change, warning: null }

    yield saveCart({ cart })
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
}

function * watchDeleteCartProduct() {
  yield takeEvery(actions.DELETE_CART_PRODUCT, deleteCartProduct)
}

function * deleteCartProduct({ payload: { cartProductId } }) {
  try {
    let cart = { ...yield select(state => state.cart) }
    delete cart[cartProductId]

    yield saveCart({ cart })
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
}

function * watchDeleteCart() {
  yield takeEvery(actions.DELETE_CART, deleteCart)
}

function * deleteCart() {
  try {
    let cart = {}

    yield saveCart({ cart })
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
}

function * saveCart({ cart }) {
  const { brandId, storeId } = getParams({ path: history.location.pathname })
  const store = (yield select(state => state.stores))[storeId]

  const deliveryMethodModel = { cartProductId: 'deliveryMethod', quantity: 1, price: 0 }
  cart.deliveryMethod = {
    ...deliveryMethodModel,
    ...cart.deliveryMethod,
    price: getDeliveryPrice({ store, cart }),
  }

  const consolidatedCart = uniteDuplicateProducts(cart)

  localStorage.setItem('ecommerceBBCart', JSON.stringify({ cart: consolidatedCart, brandId, storeId }))
  yield put({ type: actions.REDUCE_EDIT_CART, payload: consolidatedCart })
}

const getParams = ({ path }) => {
  const match = matchPath(path, {
    path: '/:brandId/:storeId?/:view?',
    exact: true,
    strict: false,
  })
  if (match.isExact)
    return match.params
  else
    return {}
}

export default function * cartSagas() {
  yield all([
    watchGetCart(),
    watchCreateCartProduct(),
    watchEditCartProduct(),
    watchDeleteCartProduct(),
    watchDeleteCart(),
  ])
}
