import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Card, Typography } from '@material-ui/core'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  rootCard: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    height: 300,
  },
  backgroundImage: {
    width: '100%',
    height: 200,
    backgroundSize: 'cover',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  card: {
    position: 'absolute',
    top: 100,
    width: 200,
    height: 200,
  },
  media: {
    display: 'flex',
    justifyContent: 'center',
    height: 200,
    borderRadius: 4,
    backgroundSize: 'cover',
    boxShadow: `0px 2px 1px -1px ${theme.palette.boxShadow.primary}, 0px 1px 1px 0px ${theme.palette.boxShadow.secondary}, 0px 1px 3px 0px ${theme.palette.boxShadow.tertiary}`,
  },
  content: {
    bottom: 0,
    position: 'absolute',
    padding: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  logoPadding: {
    flexGrow: 1,
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
  storeProperties: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 5,
  },
}))

const MobileBrandHeader = () => {
  const classes = useStyles()
  const brand = useSelector(state => state.brand)
  const stores = useSelector(state => state.stores)
  const { storeId } = useParams()
  const store = stores[storeId] || {}

  if (brand.backgroundImageUrl != null && brand.logoUrl != null)
    return (
      <div className={classes.rootCard} style={{ height: storeId != null ? 300 : 300 }}>
        <div style={{ backgroundImage: `url(${brand.backgroundImageUrl})` }} className={classes.backgroundImage} />
        <Card className={classes.card}>
          <div className={classes.media} style={{ backgroundImage: `url(${brand.logoUrl})` }} />
        </Card>
      </div>
    )
  else if (brand.logoUrl != null)
    return (
      <div className={classes.rootCard} style={{ height: storeId != null ? 300 : 300 }}>
        <div style={{ backgroundColor: brand.logoColor }} className={classes.backgroundImage} />
        <Card className={classes.card}>
          <div className={classes.media} style={{ backgroundImage: `url(${brand.logoUrl})` }} />
        </Card>
      </div>
    )
  else
    return (
      <div style={{ textAlign: 'center', padding: 10, backgroundColor: brand.logoColor }}>
        <Typography variant='h4'>
          {brand.name || brand.brandId || ''}
        </Typography>
        <Typography variant='h4'>
          {store.name}
        </Typography>
      </div>
    )
}

export default MobileBrandHeader
