import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import DeliveryModal from './DeliveryModal'

import translator from 'utility/translator'

import * as actions from 'redux/actions'

const useStyles = makeStyles((theme) => ({
  orderContainer: {
    width: '100%',
    margin: 'auto',
    paddingTop: 20,
    paddingBottom: 100,
  },
  paymentsMethodsMain: {
    marginTop: theme.spacing(4),
  },
  paymentsMethodsTitle: {
    marginBottom: theme.spacing(2),
  },
  deliveryMethodMain: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  deliveryMethods: {
    flexDirection: 'column',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const fields = [
  { label: 'address', required: true },
  { label: 'city', required: true },
  { label: 'zip', required: true, type: 'zip' },
]

const DeliveryMethod = ({ props: { values, setValues } }) => {
  const cart = useSelector(state => state.cart)
  const stores = useSelector(state => state.stores)
  const loading = useSelector(state => state.loading)
  const navigation = useSelector(state => state.navigation)
  const classes = useStyles()
  const [modal, setModal] = useState(null)
  const { storeId } = useParams()
  const store = stores[storeId] || {}
  const dispatch = useDispatch()

  const properties = store.storeProperties || {}

  const methods = navigation.modality === 'orderAtTable'
    ? [['deliveryAtTable', { active: true }]]
    : Object.entries(store.services || {}).filter(([label, { active }]) => active)

  useEffect(() => {
    if (methods.length === 1 && methods[0][0] === 'takeAway' && cart.deliveryMethod != null && cart.deliveryMethod.name == null)
      dispatch({
        type: actions.CREATE_CART_PRODUCT,
        payload: {
          cartProductId: 'deliveryMethod',
          productId: store.idDeliveryProduct,
          name: 'takeAway',
          price: store?.services?.takeAway?.price || 0,
          quantity: 1,
        },
      })
    else if (navigation.modality === 'orderAtTable' && cart.deliveryMethod != null && cart.deliveryMethod.name == null)
      dispatch({
        type: actions.CREATE_CART_PRODUCT,
        payload: {
          cartProductId: 'deliveryMethod',
          productId: store.idDeliveryProduct,
          name: 'deliveryAtTable',
          price: 0,
          quantity: 1,
        },
      })
    else if (cart?.deliveryMethod?.name === 'delivery' && (!values.address || !values.city || !values.zip))
      dispatch({ type: actions.DELETE_CART_PRODUCT, payload: { cartProductId: 'deliveryMethod' } })
  }, [methods, cart, dispatch, store, navigation.modality])

  const onChange = ({ label }) => {
    if (label === 'delivery') {
      setModal(label)
    } else if (label !== 'deliveryAtTable') {
      setValues({ ...values, ...fields.reduce((list, { label }) => ({ ...list, [label]: '' }), {}) })
      dispatch({
        type: actions.CREATE_CART_PRODUCT,
        payload: {
          cartProductId: 'deliveryMethod',
          productId: store.idDeliveryProduct,
          name: label,
          price: store?.services[label].price || 0,
          quantity: 1,
        },
      })
    }
  }

  return (
    <>
      {modal !== null ? <DeliveryModal props={{ fields, modal, setModal, values, setValues, checked: (cart.deliveryMethod || {}).name === 'delivery' }} /> : null}
      <div className={classes.deliveryMethodMain}>
        <Typography variant='h5'>
          {translator.fromLabel('deliveryMethod_title')}
        </Typography>
        <RadioGroup row aria-label='position' name='position' defaultValue='top' className={classes.deliveryMethods}>
          {methods.map(([label, method]) => (
            <FormControlLabel
              key={label}
              value='end'
              control={(
                <Radio
                  data-cy={label + '_radio'}
                  id={label}
                  checked={(cart.deliveryMethod || {}).name === label}
                  color={(cart.deliveryMethod || {}).name === label ? 'primary' : 'secondary'}
                  onChange={() => onChange({ label })}
                  onClick={() => onChange({ label })}
                  disabled={loading.length > 0}
                />
              )}
              label={translator.fromLabel(`order_${label}_label`)}
            />
          ))}
        </RadioGroup>
        {(cart.deliveryMethod || {}).name === 'delivery' ? (
          <>
            <Typography variant='body1'>
              {`${values.address}`}
            </Typography>
            <Typography variant='body1'>
              {`${values.zip} - ${values.city}`}
            </Typography>
            <Typography variant='body1'>
              {`${values.notes}`}
            </Typography>
          </>
        ) : (cart.deliveryMethod || {}).name === 'takeAway'
          ? (
            <>
              <Typography variant='body1'>
                {`${properties.name}`}
              </Typography>
              <Typography variant='body1'>
                {`${properties.address}`}
              </Typography>
            </>
            ) : null}
      </div>
    </>
  )
}

export default DeliveryMethod
