import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { TextField, Typography } from '@material-ui/core'

import { checkTimeAgainstSlots } from 'utility/timeFunctions'
import { KeyboardDatePicker } from '@material-ui/pickers'
import translator from 'utility/translator'

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: 10,
    justifyContent: 'space-between',
  },
  warning: {
    color: theme.palette.error.main,
  },
}))

const TimeChooser = ({ props: { navigation, values, setValues, errors, setErrors, loading = [], store } }) => {
  const classes = useStyles()

  const checkDeliveryDate = ({ date }) => {
    if (moment().isSameOrAfter(date))
      setErrors({ ...errors, hour: null, deliveryDate: `${translator.fromLabel('order_orderInThePast_warning')}` })
    else if (!checkTimeAgainstSlots({ timeSlots: store.timeSlots, time: date }))
      setErrors({ ...errors, hour: null, deliveryDate: `${translator.fromLabel('order_storeClosed_warning')}` })
    else
      setErrors({ ...errors, hour: null, deliveryDate: null })

    setValues({ ...values, deliveryDate: date })
  }

  const onHourChange = ({ value }) => {
    if (value == null || value === '') {
      setErrors({ ...errors, hour: 'order_fieldRequired_error' })
    } else {
      const [hours, minutes] = value.split(':')
      const date = moment().hours(hours).minutes(minutes)
      if (date.hour != null && date.hour() <= 5)
        date.add(1, 'day')

      checkDeliveryDate({ date })
    }
  }

  const onDateChange = ({ value }) => {
    let date = value
    if (values.deliveryDate != null)
      date = value.hour(values.deliveryDate.hour()).minute(values.deliveryDate.minute())
    checkDeliveryDate({ date })
  }

  return (
    <>
      <div className={classes.container}>
        <TextField
          key='hour'
          id='hour'
          label={translator.fromLabel('order_hourTextfield_label')}
          value={values.deliveryDate != null ? values.deliveryDate.format('HH:mm') : ''}
          onChange={event => onHourChange({ value: event.target.value })}
          style={{ marginRight: 10, minWidth: 150 }}
          type='time'
          error={errors.hour != null}
          helperText={translator.fromLabel(errors.hour) || ''}
          required
          disabled={loading.length > 0 || navigation.modality === 'orderAtTable'}
          InputLabelProps={{ shrink: true }}
        />
        <KeyboardDatePicker
          disableToolbar
          variant='inline'
          format='DD/MM/YYYY'
          margin='normal'
          id='date-picker-inline'
          label={translator.fromLabel('order_dateTextfield_label')}
          value={values.deliveryDate || moment()}
          onChange={date => onDateChange({ value: date })}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disablePast
          disabled
        />
      </div>
      <Typography className={classes.warning}>{errors.deliveryDate}</Typography>
    </>
  )
}

export default TimeChooser
