import React, { useEffect } from 'react'
import withErrorBoundary from 'components/componentError/withErrorBoundary'

import ThemeSelector from 'components/themeSelector/ThemeSelector.jsx'

import QRReader from 'views/qrReader/QRReader.jsx'
import ContentManager from 'views/contentManager/ContentManager'
import ErrorBar from 'components/errorBar/ErrorBar'
import LoadingBar from 'components/loadingBar/LoadingBar'

import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'redux/actions'

import './index.css'

const App = props => {
  const navigation = useSelector(state => state.navigation)
  const error = useSelector(state => Object.values(state.error)[0])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: actions.ON_START })
  }, [dispatch])

  return (
    <>
      {navigation.modality === 'qrReader'
        ? <QRReader />
        : (
          <ThemeSelector>
            <ContentManager />
          </ThemeSelector>
          )}
      <ErrorBar props={{ error, onClose: () => dispatch({ type: actions.REDUCE_DELETE_ERROR, payload: { error } }) }} />
      <LoadingBar />
    </>
  )
}

export default withErrorBoundary(App)
