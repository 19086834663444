import { put, putResolve, take, takeEvery, all, select, fork } from 'redux-saga/effects'
import * as actions from 'redux/actions'

import { formatError, formatFirebaseError } from 'redux/format'
import { getStores } from './storesSagas'
import firebase from 'redux/firebase/firebase'

export function * watchUpdateProducts() {
  yield takeEvery(actions.UPDATE_PRODUCTS, updateProducts)
}

export function * updateProducts({ payload, payload: { brandId = '', storeId = '' } }) {
  yield put({ type: actions.START_LOADING, payload: { action: 'updateProducts' } })
  try {
    const oldCartLength = Object.keys(yield select(state => state.cart)).length
    const navigation = yield select(state => state.navigation)

    yield getStores({ payload })

    yield getProducts({ payload: { ...payload, modality: navigation.modality, getProductsImages: false } })

    const cart = yield select(state => state.cart)
    const newCartLength = Object.keys(cart).length

    yield put({ type: actions.STOP_LOADING, payload: { action: 'updateProducts' } })
    if (oldCartLength !== newCartLength) {
      yield put({ type: actions.ERROR, payload: { error: formatError({ message: 'productsSaga_productCancelled_warning', code: 304 }) } })
      return false
    } else if (Object.values(cart).some(cartItem => cartItem.warning != null)) {
      yield put({ type: actions.ERROR, payload: { error: formatError({ message: 'productsSaga_productModified_warning', code: 305 }) } })
      return false
    } else {
      return true
    }
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
}

export function * watchGetProducts() {
  yield takeEvery(actions.GET_PRODUCTS, getProducts)
}

export function * getProducts({ payload, payload: { brandId = '', storeId = '', modality, getProductsImages = true } }) {
  yield put({ type: actions.START_LOADING, payload: { action: 'getProducts' } })
  try {
    yield checkStoreExistence({ storeId })

    const disponibility = modality === 'orderAtTable' ? 'orderAtTable' : 'orderOnline'
    let products = yield firebase.getProducts({ brandId, disponibility })

    if (products == null || products.length === 0)
      throw formatError({ message: 'Products not found', code: 301 })
    products = yield checkStoreProductsAvailability({ products, storeId })

    yield putResolve({ type: actions.REDUCE_EDIT_PRODUCTS, payload: products })
    yield put({ type: actions.GET_CART, payload: { ...payload, products } })

    if (getProductsImages)
      yield fork(getProductsImage, products)
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
  yield put({ type: actions.STOP_LOADING, payload: { action: 'getProducts' } })
}

function * checkStoreExistence({ storeId }) {
  let stores = yield select(state => state.stores)

  if (Object.keys(stores || {}).length === 0)
    stores = (yield take(actions.REDUCE_EDIT_STORES)).payload

  if (stores[storeId] == null)
    throw formatError({ message: `Store '${storeId}' not found`, code: 202 })

  return true
}

// eslint-disable-next-line no-unreachable-loop
const checkObject = obj => { for (let propId in obj) return true; return false }

function * checkStoreProductsAvailability({ products, storeId }) {
  const stores = yield select(state => state.stores)
  let productsAvailabilities = (stores[storeId].productsAvailabilities || {})

  let productsList = products
    .filter(product => product.productId != null && productsAvailabilities[product.productId] != null)
    .reduce((productsList, product) => ({
      ...productsList,
      [product.productId]: { ...product, price: productsAvailabilities[product.productId].price || product.price },
    }), {})

  if (!checkObject(productsList))
    throw formatError({ message: 'Products not found for this store', code: 302 })

  return productsList
}

function * getProductsImage(products) {
  try {
    const brand = yield select(state => state.brand)

    const queue = Object.keys(products).map(function * (productId) {
      const productImageUrl = yield firebase.getImageUrl({
        bucketUrl: `gs://mytec-ecommerce.appspot.com/${brand.brandId}/product_${productId}.png`,
      })
      if (productImageUrl != null)
        yield put({ type: actions.REDUCE_EDIT_PRODUCT_IMAGE, payload: { productId, productImageUrl } })
    })
    yield all(queue)
  } catch (error) {
    console.log(error)
  }
}

export default function * productsSagas() {
  yield all([
    watchUpdateProducts(),
    watchGetProducts(),
  ])
}
