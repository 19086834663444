import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Card, Typography } from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'redux/actions'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    height: 200,
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  card: {
    width: 200,
    height: 200,
    cursor: 'pointer',
    marginTop: 40,
  },
  media: {
    display: 'flex',
    justifyContent: 'center',
    height: 200,
    borderRadius: 4,
    boxShadow: `0px 2px 1px -1px ${theme.palette.boxShadow.primary}, 0px 1px 1px 0px ${theme.palette.boxShadow.secondary}, 0px 1px 3px 0px ${theme.palette.boxShadow.tertiary}`,
    backgroundSize: 'cover',
  },
  content: {
    bottom: 0,
    position: 'absolute',
    padding: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  logoPadding: {
    flexGrow: 1,
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
  storeProperties: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 5,
  },
}))

const DesktopHeader = () => {
  const classes = useStyles()
  const brand = useSelector(state => state.brand)
  const stores = useSelector(state => state.stores)
  const loading = useSelector(state => state.loading)
  const { storeId } = useParams()
  const store = stores[storeId] || {}
  const dispatch = useDispatch()

  const onClick = () => {
    if (loading.length < 1)
      dispatch({ type: actions.GO_TO_STORE_SELECTOR })
  }

  if (brand.backgroundImageUrl != null && brand.logoUrl != null)
    return (
      <div style={{ backgroundImage: `url(${brand.backgroundImageUrl})` }} className={classes.backgroundImage}>
        <Card className={classes.card} onClick={onClick}>
          <div className={classes.media} style={{ backgroundImage: `url(${brand.logoUrl})` }} />
        </Card>
      </div>
    )
  else if (brand.logoUrl != null)
    return (
      <div style={{ backgroundColor: brand.logoColor }} className={classes.backgroundImage}>
        <Card className={classes.card} onClick={onClick}>
          <div className={classes.media} style={{ backgroundImage: `url(${brand.logoUrl})` }} />
        </Card>
      </div>
    )
  else
    return (
      <div style={{ backgroundColor: brand.logoColor, textAlign: 'center', padding: 10 }}>
        <Typography variant='h4'>
          {brand.name || brand.brandId || ''}
        </Typography>
        <Typography variant='h4'>
          {store.name}
        </Typography>
      </div>
    )
}

export default DesktopHeader
