import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import Home from '@material-ui/icons/Home'
import Check from '@material-ui/icons/Check'

import translator from 'utility/translator'
import { useHistory, useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    textAlign: 'center',
  },
  button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const ThankYou = () => {
  const classes = useStyles()
  const history = useHistory()
  const { brandId, storeId } = useParams()

  return (
    <div className={classes.main}>
      <div className={classes.title} style={{ color: 'green' }}>
        <Check fontSize='large' />
        <div style={{ width: 25 }} />
        <Typography variant='h4'>{translator.fromLabel('thankYou_title')}</Typography>
      </div>
      <Typography variant='h6'>{translator.fromLabel('thankYou_text')}</Typography>
      <div className={classes.button}>
        <Tooltip title={translator.fromLabel('thankYou_home_button')} placement='top'>
          <Fab
            color='primary'
            onClick={() => history.push(`/${brandId}/${storeId}`)}
            className={classes.fab}
            aria-label='add'
          >
            <Home />
          </Fab>
        </Tooltip>
      </div>
    </div>
  )
}

export default ThankYou
