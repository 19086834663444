import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { checkTimeAgainstSlots } from 'utility/timeFunctions'

import translator from 'utility/translator'

const useStyles = makeStyles((theme) => ({
  paymentsMethodsMain: {
    marginTop: theme.spacing(4),
  },
  paymentsMethodsTitle: {
    marginBottom: theme.spacing(2),
  },
  deliveryMethodMain: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
}))

const PaymentMethods = ({ props: { values, setValues } }) => {
  const classes = useStyles()
  const loading = useSelector(state => state.loading)
  const stores = useSelector(state => state.stores)
  const brand = useSelector(state => state.brand)
  const navigation = useSelector(state => state.navigation)
  const { storeId } = useParams()
  const store = stores[storeId] || {}

  const checkMethodsForIntegrity = ([label, properties]) => properties.active &&
    (!['payPal', 'stripe'].includes(label) ||
    (label === 'payPal' && brand.payPalClientId != null && brand.payPalClientId !== '') ||
    (label === 'stripe' && brand.stripePk != null && brand.stripePk !== ''))

  const checkMethodsForOrderAtTable = ([label, properties]) => navigation.modality !== 'orderAtTable' ||
    (!['payPal', 'stripe'].includes(label) ||
    (store.orderAtTable?.onlinePayment && checkTimeAgainstSlots({ timeSlots: store.orderAtTable?.timeSlots })))

  const methods = Object.entries(store.paymentMethods || {})
    .filter(checkMethodsForIntegrity)
    .filter(checkMethodsForOrderAtTable)
    .sort((a, b) => a[0] >= b[0] ? 1 : -1)
    .map(([label, properties]) => ({ label, ...properties }))

  useEffect(() => {
    if (methods.length === 0 && values.paymentMethod == null)
      setValues({ ...values, paymentMethod: 'cashOn' })
    else if (methods.length === 1 && values.paymentMethod == null)
      setValues({ ...values, paymentMethod: methods[0].label })
  }, [methods, setValues, values])

  if (methods.length === 0)
    return (
      <div className={classes.paymentsMethodsMain}>
        <Typography variant='h5'>
          {translator.fromLabel('order_paymentMethods_title')}
        </Typography>
        <Typography variant='h6'>
          {translator.fromLabel('order_cashOn_label')}
        </Typography>
      </div>
    )

  return (
    <FormControl className={classes.paymentsMethodsMain} component='fieldset'>
      <Typography variant='h5'>
        {translator.fromLabel('order_paymentMethods_title')}
      </Typography>
      <RadioGroup aria-label='position' name='position' defaultValue='top'>
        {methods.map(({ label }) => (
          <FormControlLabel
            key={label}
            value='end'
            control={(
              <Radio
                id={label + '_radio'}
                data-cy={label + '_radio'}
                checked={values.paymentMethod === label}
                value={values[label]}
                color={values.paymentMethod === label ? 'primary' : 'secondary'}
                onChange={event => setValues({ ...values, paymentMethod: label })}
                disabled={loading.length > 0}
              />
            )}
            label={translator.fromLabel(`order_${label}_label`)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default PaymentMethods
