import React, { memo } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Divider, Typography, Toolbar } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'

import ProductItem from './ProductItem'

import translator from 'utility/translator'

const useStyles = makeStyles((theme) => ({
  noProductsMessage: {
    margin: 30,
    display: 'flex',
    justifyContent: 'center',
  },
  wrap: {
    position: 'relative',
    maxWidth: '960px',
    overscrollBehavior: 'contain',
    marginTop: 25,
    cursor: 'pointer',
  },
  headers: {
    backgroundColor: theme.palette.secondary.main,
    top: 70,
    position: 'sticky',
    zIndex: 1,
    boxShadow: '1px 1px 3px rgba(0,0,0,.4)',
  },
  headersBorderTop: {
    top: 55,
    position: 'sticky',
    zIndex: 1,
    borderTop: '20px solid ' + theme.palette.background.default,
  },
  tracks: {
    minHeight: 50,
    display: 'flex',
    overflowY: 'hidden',
    overflow: 'auto',
    scrollSnapType: 'x mandatory',
  },
}))

const MobileProductsList = memo(({ props: { loading = [], filteredProductList = [], filteredCategoriesList = [] } }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.headersBorderTop} />
      <div className={classes.wrap}>
        {filteredCategoriesList.length > 0
          ? filteredCategoriesList
              .map(category => (
                <React.Fragment key={category.categoryId}>
                  <div className={classes.headers}>
                    <Toolbar className={classes.tracks}>
                      <Typography variant='h5'>{category.name}</Typography>
                    </Toolbar>
                  </div>
                  {filteredProductList[category.productGroupId] != null
                    ? (
                      <List>
                        {filteredProductList[category.productGroupId]
                          .map(product => {
                            return (
                              <React.Fragment key={product.productId}>
                                <ListItem className={classes.product}>
                                  <ProductItem props={{ product, imagesEnabled: category.imagesEnabled }} />
                                </ListItem>
                                <Divider />
                              </React.Fragment>
                            )
                          })}
                      </List>
                      ) : <NoProductsMessage />}
                </React.Fragment>
              ))
          : (loading.length === 0 ? <NoProductsMessage /> : null)}
      </div>
    </>
  )
})

const NoProductsMessage = () => {
  const classes = useStyles()
  return (
    <div className={classes.noProductsMessage}>
      <Typography variant='h6'>
        {translator.fromLabel('productSelector_noProducts_message')}
      </Typography>
    </div>
  )
}

export default MobileProductsList
