import React from 'react'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import Modal from 'components/modal/Modal'

import { useSelector } from 'react-redux'
import translator from 'utility/translator'

const WaitingTimesModal = ({ props: { onChange = () => {}, selected } = {} }) => {
  const brand = useSelector(state => state.brand)

  return (
    <Modal>
      <Typography variant='h5'>{translator.fromLabel('waitingTimes_title')}</Typography>
      <Typography variant='body1'>{translator.fromLabel('waitingTimes_text')}</Typography>
      {(brand.waitingTimes ?? []).map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          <div style={{ height: 10 }} />
          <Button
            variant={selected == null || selected === index ? 'contained' : 'outlined'}
            color='primary'
            onClick={() => onChange(index)}
          >
            {translator.fromLabel('waitingTimes_button_' + index)}
          </Button>
        </React.Fragment>
      ))}
    </Modal>
  )
}

export default WaitingTimesModal
