import React, { useState, useEffect, useMemo } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as actions from 'redux/actions'

import { useSize } from 'components/sizeSwitcher/SizeSwitcher'

import MobileProductsList from './components/MobileProductsList'
import DesktopProductsList from './components/DesktopProductsList'

const checkProductSort = (a, b) => {
  if (a.rank == null && b.rank == null)
    return a.name > b.name ? 1 : -1
  else
    return (a.rank ?? Infinity) - (b.rank ?? Infinity)
}

const ProductSelector = () => {
  const state = useSelector(state => state)
  const history = useHistory()
  const { brandId, storeId } = useParams()
  const { mobile } = useSize()
  const [searchFilter, setSearchFilter] = useState('')
  const [categoryFilter, setCategoryFilter] = useState('')
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const searchString = urlParams.get('search') || ''
    const categoryString = urlParams.get('categoryId') || ''
    if (searchString !== searchFilter)
      setSearchFilter(searchString)
    if (categoryString !== categoryFilter)
      setCategoryFilter(categoryString)
  }, [location.pathname, location.search, searchFilter, categoryFilter])

  useEffect(() => {
    if (Object.keys(state.stores || {}).length > 0 && state.stores[storeId] == null)
      dispatch({ type: actions.GO_TO_STORE_SELECTOR })
  }, [state.stores, brandId, storeId, history])

  const filteredProductList = useMemo(() => (
    Object.values(state.products || {})
      .filter(product => searchFilter === '' ||
        product.name.toLowerCase().includes(searchFilter.toLowerCase()))
      .sort((a, b) => checkProductSort(a, b))
      .reduce((list, product) => {
        if (product.productGroups != null)
          for (let productGroupId of product.productGroups)
            if (list[productGroupId] == null)
              list[productGroupId] = [product]
            else
              list[productGroupId].push(product)
        return list
      }, {})
  ), [state.products, searchFilter])
  const filteredCategoriesList = useMemo(() => (
    Object.values(state.categories || {})
      .filter(category =>
        (categoryFilter !== '' && category.categoryId === categoryFilter) ||
        (categoryFilter === '' && filteredProductList[category.productGroupId] != null),
      )
      .sort((a, b) => a.position > b.position ? 1 : -1)
  ), [state.categories, categoryFilter, filteredProductList])

  if (mobile)
    return <MobileProductsList props={{ loading: state.loading, filteredProductList, filteredCategoriesList }} />
  else
    return <DesktopProductsList props={{ loading: state.loading, filteredProductList, filteredCategoriesList }} />
}

export default ProductSelector
