import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import ArrowBack from '@material-ui/icons/ArrowBack'

import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { PayPalButton } from 'react-paypal-button-v2'

import Stripe from './components/Stripe.js'

import translator from 'utility/translator'

import * as actions from 'redux/actions'

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    width: '100%',
    padding: '10px 0px 30px 0px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  mobileFab: {
    position: 'absolute',
    right: 25,
    bottom: 25,
    zIndex: 2000,
  },
  footerAppBar: {
    top: 'auto',
    bottom: 0,
  },
  footerToolBar: {
    minHeight: 52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: theme.values.maxWidth,
    margin: 'auto',
  },
}))

const Payment = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const order = useSelector(state => state.order)
  const loading = useSelector(state => state.loading)
  const brand = useSelector(state => state.brand)
  const { brandId, storeId } = useParams()
  const history = useHistory()

  const goBack = () => {
    history.push(`/${brandId}/${storeId}/order`)
  }

  const onError = error => {
    dispatch({ type: actions.ERROR, payload: { error } })
  }

  const onSuccess = (paymentData) => {
    dispatch({
      type: actions.CREATE_PAYMENT,
      payload: { paymentData },
    })
  }

  return (
    <div>
      <div className={classes.title}>
        <Button variant='outlined' disabled={loading.legth > 0} onClick={goBack}>
          <ArrowBack />
        </Button>
        <Typography variant='h4'>{translator.fromLabel('payment_title')}</Typography>
        <div style={{ height: 2, width: 50 }} />
      </div>
      {order.paymentMethod === 'payPal'
        ? (
          <div style={{ backgroundColor: 'aliceblue', padding: 15, borderRadius: 5 }}>
            <PayPalButton
              amount={order.totalPrice}
              shippingPreference='NO_SHIPPING'
              onSuccess={(a, data) => onSuccess(data)}
              catchError={onError}
              options={{
                clientId: brand.payPalClientId,
                currency: 'EUR',
                disableFunding: 'sofort,mybank',
              }}
            />
          </div>
          ) : order.paymentMethod === 'stripe'
            ? (
              <Stripe props={{
                onError: error => onError(new Error(String(error))),
                onSuccess,
                amount: order.totalPrice * 100,
                stripId: brand.stripePk,
                brandId: brand.brandId,
              }}
              />
              )
            : null}
    </div>
  )
}

export default Payment
