import React, { useState } from 'react'

import DeliveryModal from './components/DeliveryModal.jsx'
import FirstModal from './components/FirstModal.jsx'
import OrderAtTableModal from './components/OrderAtTableModal.jsx'

import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'redux/actions'

const WelcomeModal = () => {
  const [modal, setModal] = useState('firstModal')
  const stores = useSelector(state => state.stores)
  const brand = useSelector(state => state.brand)
  const navigation = useSelector(state => state.navigation)
  const loading = useSelector(state => state.loading)
  const { brandId, storeId } = useParams()
  const store = stores[storeId]
  const dispatch = useDispatch()
  const multipleStores = Object.keys(stores).length > 1

  React.useEffect(() => {
    if (navigation.modality != null)
      setModal(null)
  }, [navigation.table])

  const goToStoresSelector = () => {
    dispatch({ type: actions.GO_TO_STORE_SELECTOR })
  }

  const onSelect = ({ modality, param }) => {
    switch (modality) {
      case 'delivery':
        dispatch({ type: actions.REDUCE_EDIT_USER, payload: { zip: param } })
        dispatch(({ type: actions.REDUCE_EDIT_NAVIGATION, payload: { modality } }))
        dispatch({ type: actions.GET_PRODUCTS, payload: { brandId, storeId, modality } })
        setModal(null)
        break
      case 'takeAway':
        dispatch(({ type: actions.REDUCE_EDIT_NAVIGATION, payload: { modality } }))
        dispatch({ type: actions.GET_PRODUCTS, payload: { brandId, storeId, modality } })
        setModal(null)
        break
      case 'orderAtTable':
        if (loading.length === 0)
          dispatch({ type: actions.START_ORDER_AT_TABLE, payload: { brandId, storeId, param } })
        break
      default:
        console.error('Modality "' + modality + '"not recognized')
        break
    }
  }

  switch (modal) {
    case 'firstModal':
      return (
        <FirstModal props={{
          loading,
          multipleStores,
          brand,
          store,
          setModal,
          onSelect,
          goToStoresSelector,
        }}
        />
      )
    case 'deliveryModal':
      return <DeliveryModal props={{ multipleStores, store, setModal, onSelect, goToStoresSelector }} />
    case 'orderAtTable':
      return <OrderAtTableModal props={{ setModal, onSelect }} />
    default:
      return null
  }
}

export default WelcomeModal
