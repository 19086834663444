const error = {
  error_reload_button: 'Ricarica la pagina',
  error_passedDownError_proposed_action: 'Azione proposta',
  error_passedDownError_text: 'Questo non era previsto!',
  error_passedDownError_title: 'Oops... ^^"',
}

const dates = {
  Sun: 'Dom',
  Mon: 'Lun',
  Tue: 'Mar',
  Wed: 'Mer',
  Thu: 'Gio',
  Fri: 'Ven',
  Sat: 'Sab',

  Jan: 'Gen',
  Feb: 'Feb',
  Mar: 'Mar',
  Apr: 'Apr',
  May: 'Mag',
  Jun: 'Giu',
  Jul: 'Lug',
  Aug: 'Ago',
  Sep: 'Set',
  Oct: 'Ott',
  Nov: 'Nov',
  Dec: 'Dic',
}

const cart = {
  cart_title: 'Carrello',
  cart_clear_button: 'Svuota carrello',
  cart_totalPrice_label: 'Prezzo totale: ',
  cart_storeClosed_warning: 'Il negozio è chiuso. Prossima apertura prevista: ',
  cart_beneathMinOrder_warning: 'Ordine sotto alla soglia minima di',
  cart_order_button: 'Prosegui',
  cart_deliveryPrice: 'Costi di gestione: ',
  cart_desktopOrder_button: 'Ordina   ',
  cartProduct_priceChanged_warning: 'Prezzo modificato',
  delivery_title: 'Consegna a domicilio',
  delivery_addDelivery_button: 'Aggiungi consegna a domicilio',
  delivery_addressTextfield_label: 'Indirizzo di consegna',
  delivery_cityTextfield_label: 'Città',
  delivery_zipTextfield_label: 'CAP',
  delivery_notesTextfield_label: 'Note',
  delivery_fieldRequired_error: 'Campo obbligatorio',
  delivery_zipCodeNotServed_error: 'CAP al di fuori della zona di consegna',
  delivery_save_button: 'Aggiungi',
  delivery_close_button: 'Chiudi',
  delivery_cartProduct_text: 'Consegna a domicilio',
  deliveryModal_title: 'Consegna a domicilio',
  deliveryModal_price: 'Prezzo: ',
  deliveryModal_freeThreshold: 'Consegna gratuita per ordini superiori a ',
}

const home = {
  home_title: 'Pagina non trovata',
  home_text: 'Il marchio o il punto vendita che stavi cercando non esiste oppure ha cambiato nome.\nRicontrolla i dati inseriti.\nGrazie!',
  thankYou_home_button: 'Home',
  home_brandNotFound_error: 'Il marchio ricercato non esiste.\nRicontrolla i dati inseriti.\nGrazie!',
  qrReader_title: 'Benvenuto!',
  qrReader_text: 'Scansiona il codice QR del punto vendita o del tavolo',
}

const menu = {
  menu_searchProduct_placeholder: 'Ricerca prodotto...',
  menu_searchStore_placeholder: 'Punto vendita...',
  menu_cart_button: 'Carrello',
  menu_categories_button: 'Categorie',
  menu_back_button: 'Indietro',
  menu_allCategories_label: 'Tutte le categorie',
}

const order = {
  order_fieldsTitle: 'Inserisci i tuoi dati',
  order_nameTextfield_label: 'Nome',
  order_surnameTextfield_label: 'Cognome',
  order_emailTextfield_label: 'Email',
  order_phoneTextfield_label: 'Telefono',
  order_fieldRequired_error: 'Campo obbligatorio',
  order_emailNotValid_error: 'Email non valida',
  order_phoneNotValid_error: 'Telefono non valido',
  order_hourTextfield_label: 'Orario di consegna',
  order_dateTextfield_label: 'Data',
  order_paymentMethods_title: 'Metodi di pagamento',
  order_cashOn_label: 'Contanti',
  deliveryMethod_title: 'Metodo di consegna',
  deliveryMethod_deliveryAtHome_text: 'Consegna a domicilio',
  deliveryMethod_takeAway_text: 'Ritiro presso il punto vendita',
  order_order_button: 'Conferma l\'ordine',
  thankYou_title: 'Grazie per il tuo ordine',
  thankYou_text: 'Il tuo ordine è stato correttamente ricevuto. Buon Appetito!',
  order_storeTimeslots_label: 'Orari di apertura ',
  order_storeClosed_warning: 'Orario non disponibile.',
  noOpeningTimeFoundToday: 'chiuso per oggi',
  order_noTimeslots_warning: 'Punto vendita chiuso',
  order_orderInThePast_warning: 'Non è possibile ordinare nel passato',
  order_delivery_label: 'Consegna a domicilio',
  order_takeAway_label: 'Take away',
  order_deliveryAtTable_label: 'Consegna al tavolo',
  order_cartEmpty_error: 'Carrello vuoto',
}

const product = {
  productItem_variations_title: 'Variazioni disponibili',
  productItem_addProduct_button: 'Aggiungi al carrello',
  productSelector_noProducts_message: 'Nessun prodotto',
  productItem_notes_label: 'Note aggiuntive',
  productsSaga_productCancelled_warning: 'Uno o più dei prodotti inseriti nel carrello non sono più disponibili',
  productsSaga_productModified_warning: 'Uno o più dei prodotti inseriti nel carrello sono stati modificati',
  waitingTimes_title: 'Tempi di consegna',
  waitingTimes_text: 'Quando vuoi ricevere questo piatto?',
  waitingTimes_button_0: 'Subito',
  waitingTimes_button_1: 'Dopo',
  waitingTimes_button_2: 'Post',
}

const store = {
  storeSelect_title: 'Seleziona un punto vendita',
  storeSelector_services: 'Servizi offerti',
  storeSelector_takeAway: 'Asporto',
  storeSelector_delivery: 'Consegna a domicilio',
  storeSelector_zipCodes: 'CAP di consegna',
  storeSelector_noActiveStores_warning: 'Non ci sono punti vendita disponibili al momento. Si prega di provare più tardi.',
}

const welcomeModal = {
  welcomeModal_title: 'Scegli il tuo servizio',
  welcomeModal_intollerances_title: 'Hai un\'allergia o un\'intolleranza alimentare?',
  welcomeModal_text: 'Se hai un\'allergia o un\'intolleranza alimentare (o se una persona per la quale stai effettuando un ordine ne ha), telefona immediatamente al ristorante a questo numero:',
  welcomeModal_takeAway_button: 'Take away',
  welcomeModal_delivery_button: 'Consegna a domicilio',
  welcomeModal_deliveryModal_title: 'Seleziona il tuo cap',
  welcomeModal_deliverModal_error: 'Il cap inserito non è supportato.',
  welcomeModal_zipCodeError_button: 'Cambia punto vendita',
  welcomeModal_goBack_button: 'Indietro',
  welcomeModal_supportedZip_text: 'CAP serviti: ',
  welcomeModal_deliveryModal_textfield_label: 'Cap',
  welcomeModal_confirm_button: 'Conferma',
  welcomeModal_orderAtTable_title: 'Ordine al tavolo',
  welcomeModal_orderAtTable_text: 'Inquadra il codice QR del tavolo',
  welcomeModal_orderAtTable_button: 'Ordine al tavolo',
  orderAtTable_band_title: 'Ordinazioni per il tavolo: ',
}

const payments = {
  order_payPal_label: 'PayPal',
  order_stripe_label: 'Carta di credito',
  payment_title: 'Pagamento',
  payment_text: 'Procedi con il pagamento',
  stripe_name_label: 'Nome',
  stripe_email_label: 'Email',
  stripe_phone_label: 'Telefono',
  stripe_amount_label: 'Totale: ',
  stripe_cardNumber_label: 'Numero carta',
  stripe_error_title: 'Errore di pagamento',
  stripe_error_text: 'Siamo molto spiacenti. Per favore seleziona una modalità alternativa di pagamento',
}

const waitOrder = {
  waitOrder_home_button: 'Home',
  waitOrder_orderId_label: 'Id ordine: ',
  waitOrder_orderAccepted_title: 'Ordine accettato',
  waitOrder_orderAccepted_text: 'Il tuo ordine è stato accettato, buon appetito!',
  waitOrder_orderRejected_title: 'Errore',
  waitOrder_orderRejected_text: 'Ci dispiace, il tuo ordine non è arrivato alla cassa. Contatta il gestore per risolvere il problema',
  waitOrder_orderWaiting_title: 'Ordine in elaborazione',
  waitOrder_orderWaiting_text: 'Il tuo ordine è in elaborazione, attendi una conferma dell\'operazione',
  waitOrder_orderPending_title: 'Ordine ricevuto',
  waitOrder_orderPending_text: 'Il tuo ordine è stato ricevuto dal sistema di cassa. Attendi la conferma dell\'esito positivo dell\'operazione',
  waitOrder_orderTimeout_warning: 'La trasmissione dell\'ordine è in ritardo. Contatta il gestore del locale.',
  waitOrder_cancelOrder_button: 'Annulla ordine',
}

const toPrice = price => {
  return String(price.toFixed(2)).replace('.', ',')
}

export const italian = {
  ...error,
  ...dates,
  ...cart,
  ...home,
  ...menu,
  ...order,
  ...payments,
  ...product,
  ...store,
  ...welcomeModal,
  ...waitOrder,
  toPrice,
}
