/* global localStorage */
import { put, select, call, fork, take, takeEvery } from 'redux-saga/effects'
import * as actions from 'redux/actions'
import { formatError, formatFirebaseError } from 'redux/format'
import CryptoJS from 'crypto-js'
import { history } from 'index'
import firebase from 'redux/firebase/firebase'

const secretKey = '12V34IsTddTf2O345RhIfdsAjfeTrereIfdAasdaM123O'
const queryRegEx = /^brandId=[a-z0-9]+&storeId=[a-z0-9]+&table=[A-Za-z0-9]+&kiosk=(false|true)$/
const delay = time => new Promise(resolve => setTimeout(resolve, time))

const extractParameters = param => {
  const encrypted = decodeURIComponent(param.split('orderAtTable=')[1])
  const decrypted = CryptoJS.AES.decrypt(encrypted, secretKey)
  const query = decrypted.toString(CryptoJS.enc.Utf8)

  if (!query.match(queryRegEx))
    throw formatError({ message: `QR code '${param}' not recognized`, code: 501 })

  return ({
    param,
    brandId: query.replace(/^brandId=/, '').replace(/&storeId=[a-z0-9]+&table=[A-Za-z0-9]+&kiosk=(false|true)$/, ''),
    storeId: query.replace(/^brandId=[a-z0-9]+&storeId=/, '').replace(/&table=[A-Za-z0-9]+&kiosk=(false|true)$/, ''),
    table: query.replace(/^brandId=[a-z0-9]+&storeId=[a-z0-9]+&table=/, '').replace(/&kiosk=(false|true)$/, ''),
    kiosk: query.replace(/^brandId=[a-z0-9]+&storeId=[a-z0-9]+&table=[A-Za-z0-9]+&kiosk=/, '') === 'true',
  })
}

const checkParameters = (params, brandId, storeId) => {
  if (params.brandId !== brandId)
    throw formatError({ message: `QR code '${params.param}' not valid. BrandId not valid`, code: 502 })

  if (params.storeId !== storeId)
    throw formatError({ message: `QR code '${params.param}' not valid. StoreId not valid`, code: 503 })

  return params
}

function * startOrderAtTable({ payload: { brandId, storeId, param } = {} }) {
  yield put({ type: actions.START_LOADING, payload: { action: 'startOrderAtTable' } })
  try {
    const { table, kiosk } = checkParameters(extractParameters(param), brandId, storeId)

    yield put({ type: actions.REDUCE_EDIT_NAVIGATION, payload: { modality: 'orderAtTable', table, kiosk } })
    yield put({ type: actions.GET_PRODUCTS, payload: { brandId, storeId, modality: 'orderAtTable' } })

    yield take([actions.REDUCE_EDIT_CART, actions.CMD_NO_CART_SAVED]) // wait for saved cart to be loaded

    if (!kiosk)
      yield fork(startTimer, { brandId, storeId })
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
  yield put({ type: actions.STOP_LOADING, payload: { action: 'startOrderAtTable' } })
}

function * startTimer({ brandId, storeId }) {
  yield call(delay, 10800000) // 3 hours
  yield put({ type: actions.STOP_ORDER_AT_TABLE, payload: { brandId, storeId } })
}

function * stopOrderAtTable({ payload: { brandId, storeId } = {} }) {
  yield put({ type: actions.START_LOADING, payload: { action: 'stopOrderAtTable' } })
  try {
    yield put({ type: actions.GO_HOME, payload: { brandId, storeId } })

    yield put({ type: actions.REDUCE_EDIT_NAVIGATION, payload: { modality: 'takeAway', table: null } })
    yield put({ type: actions.GET_PRODUCTS, payload: { brandId, storeId, modality: 'takeAway' } })

    yield put({ type: actions.DELETE_CART_PRODUCT, payload: { cartProductId: 'deliveryMethod' } })
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
  yield put({ type: actions.STOP_LOADING, payload: { action: 'stopOrderAtTable' } })
}

function * waitOrderAtTable({ payload, payload: { brandId, storeId, orderId } = {} }) {
  yield put({ type: actions.START_LOADING, payload: { action: 'waitOrderAtTable' } })
  try {
    history.push(`/${brandId}/${storeId}/waitOrder`)
    yield put({ type: actions.REDUCE_EDIT_ORDER, payload })

    yield firebase.setWatchSubCollectionDoc({
      collectionId: 'Brands',
      docId: brandId,
      subCollectionId: 'Orders',
      subDocId: String(orderId),
      setChanges,
    })
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
  yield put({ type: actions.STOP_LOADING, payload: { action: 'waitOrderAtTable' } })
}

function * setChanges({ doc, channel }) {
  const order = yield select(state => state.order)

  if (order.orderId !== doc?.orderId)
    return false

  yield put({ type: actions.REDUCE_EDIT_ORDER_PROPS, payload: { status: doc.status } })

  if (doc.status === 'accepted' || doc.status === 'rejected') {
    channel?.close()
    localStorage.setItem('ecommerceBBCart', '{}')
  } else if (doc.status === 'canceledByClient') {
    channel?.close()
  }
}

function * stopWaitOrderAtTable({ payload: { brandId, storeId } }) {
  yield put({ type: actions.DELETE_CART })
  yield put({ type: actions.GO_HOME, payload: { brandId, storeId } })
}

function * cancelOrderAtTable({ payload: { brandId, storeId, orderId } }) {
  try {
    yield firebase.updateSubCollectionDoc({
      collectionId: 'Brands',
      docId: brandId,
      subCollectionId: 'Orders',
      subDocId: String(orderId),
      key: 'status',
      data: 'canceledByClient',
    })
    yield put({ type: actions.STOP_LOADING, payload: { action: 'waitOrderAtTable' } })
    yield put({ type: actions.GO_HOME, payload: { brandId, storeId } })
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
}

export default function * orderAtTableSagas() {
  yield takeEvery(actions.START_ORDER_AT_TABLE, startOrderAtTable)
  yield takeEvery(actions.STOP_ORDER_AT_TABLE, stopOrderAtTable)
  yield takeEvery(actions.WAIT_ORDER_AT_TABLE, waitOrderAtTable)
  yield takeEvery(actions.STOP_WAIT_ORDER_AT_TABLE, stopWaitOrderAtTable)
  yield takeEvery(actions.CANCEL_ORDER_AT_TABLE, cancelOrderAtTable)
}
