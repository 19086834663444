import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import * as actions from 'redux/actions'

import Variations from './components/Variations'
import WaitingTimesModal from 'components/waitingTimesModal/WaitingTimesModal.jsx'
import { ProductHeader, ProductActions, Description } from './components/ProductParts'
import WaitingTimesButton from 'components/waitingTimesButton/WaitingTimesButton.jsx'

const useStyles = makeStyles((theme) => ({
  main: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 100,
  },
  productContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    marginTop: 20,
  },
  backgroundImage: {
    width: '100%',
    height: 200,
    backgroundSize: 'cover',
    position: 'fixed',
  },
}))

const Product = () => {
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const location = useLocation()

  const { brandId, storeId } = useParams()
  const urlParams = new URLSearchParams(location.search)
  const productId = urlParams.get('productId')
  const cartProductId = urlParams.get('cartProductId')
  const product = state.products[productId] || {}

  const [savedVariations, setSavedVariations] = useState([])
  const [quantity, setQuantity] = useState(1)
  const [notes, setNotes] = useState('')
  const [modal, setModal] = useState(null)

  const topOfPage = React.useRef(null)

  useEffect(() => {
    const scrollToTop = () => {
      topOfPage.current.scrollIntoView({ behavior: 'auto' })
    }
    scrollToTop()
  }, [])

  const getProduct = () => {
    if (state.products[productId] == null)
      history.push(`/${brandId}/${storeId}`)

    if (cartProductId != null) {
      const cartProduct = state.cart[cartProductId]
      if (cartProduct != null && state.products[cartProduct.productId] != null) {
        setSavedVariations(cartProduct.variations.map(variation => variation.variationId))
        setQuantity(cartProduct.quantity)
        setNotes(cartProduct.notes || '')
      } else {
        history.push(`/${brandId}/${storeId}`)
      }
    }
  }

  useEffect(getProduct, [location])

  const createCartProduct = () => {
    if (cartProductId == null && state.navigation.modality === 'orderAtTable' && state.brand.waitingTimes?.length > 1)
      setModal(true)
    else if (state.navigation.modality === 'orderAtTable' && state.brand.waitingTimes?.length === 1)
      sendAtTableCartProduct(0)
    else
      sendBasicCartProduct()
  }

  const sendBasicCartProduct = () => {
    dispatch({
      type: actions.CREATE_CART_PRODUCT,
      payload: {
        ...product,
        savedVariations,
        cartProductId,
        quantity,
        notes,
        waitingTime: 0,
      },
    })
    history.goBack()
  }

  const sendAtTableCartProduct = waitingTime => {
    setModal(false)
    dispatch({
      type: actions.CREATE_CART_PRODUCT,
      payload: {
        ...product,
        savedVariations,
        cartProductId,
        quantity,
        notes,
        waitingTime,
      },
    })
    history.goBack()
  }

  const calculatedPrice = savedVariations
    .reduce((price, variationId) => price + Number(product.variations[variationId].price), product.price)

  return (
    <>
      <div className={classes.main}>
        <div data-cy={productId + '_productItem'} className={classes.productContainer}>
          <div style={{ float: 'left', clear: 'both' }} ref={topOfPage} />
          <ProductHeader props={{ name: product.name, productImageUrl: product.productImageUrl, productId, savedVariations, calculatedPrice }} />
          {cartProductId != null
            ? <WaitingTimesButton props={{ cartProduct: state.cart[cartProductId] }} />
            : null}
          <Description props={{ description: product.description }} />
          <ProductActions props={{ quantity, setQuantity, notes, setNotes, createCartProduct }} />
          <div style={{ height: 16 }} />
          <Variations props={{ variations: product.variations, savedVariations, setSavedVariations }} />
        </div>
      </div>
      {modal
        ? <WaitingTimesModal props={{ onChange: sendAtTableCartProduct }} />
        : null}
    </>
  )
}

export default Product
