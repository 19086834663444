import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

import { useSelector, useDispatch } from 'react-redux'

import translator from 'utility/translator'
import WaitingTimesModal from 'components/waitingTimesModal/WaitingTimesModal.jsx'

import * as actions from 'redux/actions'

const useStyles = makeStyles((theme) => ({
  waitingButton: {
    color: theme.palette.primary.main,
    width: '75%',
    margin: '5px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}))

const WaitingTimesButton = ({ props: { cartProduct: { cartProductId, waitingTime } = {}, disableButtons } = {} }) => {
  const [modal, setModal] = React.useState(false)
  const dispatch = useDispatch()
  const brand = useSelector(state => state.brand)
  const classes = useStyles()

  const onChange = waitingTime => {
    setModal(false)
    dispatch({
      type: actions.EDIT_CART_PRODUCT,
      payload: { cartProductId, change: { waitingTime } },
    })
  }

  if (brand.waitingTimes?.length <= 1)
    return null

  return (
    <>
      <div className={classes.waitingButton}>
        <Divider />
        <Button
          color='primary'
          onClick={event => { event.stopPropagation(); setModal(true) }}
          disabled={disableButtons || (!(Array.isArray(brand.waitingTimes) && brand.waitingTimes.length > 1))}
        >
          {translator.fromLabel('waitingTimes_button_' + waitingTime)}
        </Button>
        <Divider />
      </div>
      {modal
        ? <WaitingTimesModal props={{ onChange, selected: waitingTime }} />
        : null}
    </>
  )
}

export default WaitingTimesButton
