import React from 'react'

import { extractTimeSlots } from 'utility/timeFunctions'
import { Typography } from '@material-ui/core'

import translator from 'utility/translator'
import moment from 'moment'

const TimeSlots = ({ props: { store, date = moment() } }) => {
  const timeSlots = extractTimeSlots({ store, date })
  return (
    <div style={{ paddingTop: 30 }}>
      <Typography variant='h5'>{translator.fromLabel('order_storeTimeslots_label') + date.format('DD/MM')}</Typography>
      {timeSlots.length > 0
        ? timeSlots.map(timeslot => <Typography key={timeslot} variant='body1'>{timeslot}</Typography>)
        : <Typography style={{ color: 'red' }} variant='h5'>{translator.fromLabel('order_noTimeslots_warning')}</Typography>
      }
    </div>
  )
}

export default TimeSlots
