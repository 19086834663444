import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000,
  },
}))

const MosquitoNet = ({ onClose = () => { console.log('Tuca nò!') } } = {}) => {
  const classes = useStyles()

  return (
    <div className={classes.container} onClick={onClose} />
  )
}

export default MosquitoNet
