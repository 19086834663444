import React, { useState, useEffect } from 'react'

import { AppBar, Drawer, Toolbar, Tooltip, InputBase, IconButton } from '@material-ui/core'
import Badge from '@material-ui/core/Badge'
import { fade, makeStyles } from '@material-ui/core/styles'

import { ArrowBack, Clear, Menu, Search, ShoppingCart, Home } from '@material-ui/icons'

import { useHistory, useLocation, useParams } from 'react-router-dom'

import Sidebar from './Sidebar'

import translator from 'utility/translator'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
  },
  container: {
    maxWidth: theme.values.maxWidth || 1000,
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
    minHeight: 50,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    margin: 5,
  },
  title: {
    flexGrow: 1,
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
  },
  clearIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  clearButton: {
    padding: 0,
    borderRadius: 0,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 0),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  drawer: {
    paddingTop: 20,
  },
}))

const updateParams = ({ target, value, history, location, pathname }) => {
  let currentUrlParams = new URLSearchParams(location.search)

  if (value !== '')
    currentUrlParams.set(target, value)
  else
    currentUrlParams.delete(target)
  currentUrlParams.delete('productId')

  if (pathname != null)
    history.push({ pathname, search: currentUrlParams.toString() })
  else
    history.push({ search: currentUrlParams.toString() })
}

const MenuView = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const [value, setValue] = useState('')
  const state = useSelector(state => state)
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { brandId, storeId, view } = useParams()

  useEffect(() => {
    let currentUrlParams = new URLSearchParams(location.search)
    setValue(currentUrlParams.get('search') || '')
  }, [location.pathname, location.search])

  const openCart = () => {
    history.push(`/${brandId}/${storeId}/cart`)
  }

  const cartLength = Object.values(state.cart || {})
    .filter(cartProduct => cartProduct.cartProductId !== 'deliveryMethod')
    .reduce((number, cartProduct) => (number += (cartProduct.quantity || 0)), 0)

  if (view === 'waitOrder')
    return null
  return (
    <AppBar position='sticky' style={{ top: 0, left: 0, right: 0, width: '100%' }}>
      <div style={{ pointerEvents: state.loading.length > 0 ? 'none' : 'auto' }} className={classes.background}>
        <Toolbar disableGutters className={classes.container}>
          {view != null && view !== 'thankYou'
            ? (
              <Tooltip title={translator.fromLabel('menu_back_button')}>
                <div>
                  <IconButton
                    color='inherit'
                    edge='start'
                    className={classes.menuButton}
                    aria-label='back'
                    onClick={() => history.goBack()}
                  >
                    <ArrowBack />
                  </IconButton>
                </div>
              </Tooltip>
              ) : view === 'thankYou'
                ? (
                  <Tooltip title={translator.fromLabel('menu_home_button')}>
                    <div>
                      <IconButton
                        color='inherit'
                        edge='start'
                        className={classes.menuButton}
                        aria-label='home'
                        onClick={() => history.push(`/${brandId}/${storeId}`)}
                      >
                        <Home />
                      </IconButton>
                    </div>
                  </Tooltip>
                  ) : null}
          <div style={{ marginLeft: view == null ? 15 : 0 }} className={classes.search}>
            <div className={classes.searchIcon}>
              <Search />
            </div>
            <InputBase
              value={value}
              placeholder={translator.fromLabel(`menu_search${storeId != null ? 'Product' : 'Store'}_placeholder`)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={event => {
                const value = event.target.value
                setValue(value)
                updateParams({ target: 'search', value, history, location, pathname: storeId != null ? `/${brandId}/${storeId}` : `/${brandId}` })
              }}
            />
            <div className={classes.clearIcon}>
              <IconButton
                onClick={() => {
                  setValue('')
                  updateParams({ target: 'search', value: '', history, location })
                }}
                className={classes.clearButton}
                color='inherit'
              >
                <Clear />
              </IconButton>
            </div>
          </div>
          <Tooltip title={translator.fromLabel('menu_cart_button')}>
            <div>
              <IconButton
                edge='start'
                className={classes.menuButton}
                aria-label='cart'
                onClick={openCart}
                color={['cart', 'order'].includes(view) ? 'primary' : 'inherit'}
                disabled={state.stores[storeId] == null || Object.keys(state.cart).length === 0}
              >
                <Badge
                  className={classes.badge}
                  badgeContent={cartLength}
                  color='primary'
                  invisible={cartLength === 0 || ['cart', 'order'].includes(view)}
                >
                  <ShoppingCart />
                </Badge>
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title={translator.fromLabel('menu_categories_button')}>
            <div>
              <IconButton
                onClick={() => setOpenMenu(true)}
                edge='start'
                className={classes.menuButton}
                aria-label='categories'
                disabled={state.stores[storeId] == null}
                color='inherit'
              >
                <Menu />
              </IconButton>
            </div>
          </Tooltip>
          <Drawer className={classes.drawer} anchor='right' open={openMenu} onClick={() => setOpenMenu(false)} onClose={() => setOpenMenu(false)}>
            <Sidebar props={{ openMenu, setOpenMenu }} />
          </Drawer>
        </Toolbar>
      </div>
    </AppBar>
  )
}

export default MenuView
