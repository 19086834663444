import React, { memo } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { Divider, List, ListItem } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

import ExpandMore from '@material-ui/icons/ExpandMore'

import translator from 'utility/translator'

const useStylesVariations = makeStyles((theme) => ({
  variationList: {
    width: '100%',
    maxWidth: theme.values.componentsMaxWidth,
  },
  variationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    padding: '0px 5%',
  },
  title: {
  },
  variantName: {
    flex: 1,
  },
  expanesionPanel: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    margin: '20px 0px',
  },
}))

const Variations = memo(({ props: { variations = {}, savedVariations = [], setSavedVariations = () => {} } }) => {
  const onChange = variationId => {
    if (savedVariations.includes(variationId)) {
      const newList = JSON.parse(JSON.stringify(savedVariations))
      newList.splice(newList.indexOf(variationId), 1)
      setSavedVariations(newList)
    } else {
      setSavedVariations([...savedVariations, variationId])
    }
  }

  if (Object.keys(variations).length === 0)
    return null

  if (Object.keys(variations).length < 4)
    return <BasicVariationsList props={{ variations, onChange, savedVariations }} />

  return <ExpandedVariationsList props={{ variations, onChange, savedVariations }} />
}, (p, n) => p.props.savedVariations.length === n.props.savedVariations.length)

const BasicVariationsList = ({ props: { variations, onChange, savedVariations } }) => {
  const classes = useStylesVariations()
  return (
    <>
      <Typography variant='h6' className={classes.title}>
        {translator.fromLabel('productItem_variations_title')}
      </Typography>
      <List className={classes.variationList}>
        <Divider />
        {Object.values(variations)
          .sort((a, b) => (a.name > b.name) ? 1 : -1)
          .map(variation => (
            <React.Fragment key={variation.variationId}>
              <ListItem onClick={() => onChange(variation.variationId)} className={classes.variationContainer} disableGutters>
                <Checkbox
                  checked={savedVariations.includes(variation.variationId)}
                  inputProps={{ 'aria-label': variation.name, 'data-cy': variation.variationId + '_checkbox' }}
                  color='primary'
                />
                <Typography variant='body1' className={classes.variantName}>
                  {variation.name}
                </Typography>
                <Typography variant='body1'>
                  {(variation.price > 0 ? '+' : '') + translator.toPrice(variation.price) + '€'}
                </Typography>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
      </List>
    </>
  )
}

const ExpandedVariationsList = ({ props: { variations, onChange, savedVariations } }) => {
  const classes = useStylesVariations()
  return (
    <Accordion className={classes.expanesionPanel} defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls='VariationsExpansions'
        data-cy='expansionPanelSummary'
      >
        <Typography>{translator.fromLabel('productItem_variations_title')}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.variationsPanel}>
        <List className={classes.variationList}>
          <Divider />
          {Object.values(variations)
            .sort((a, b) => (a.name > b.name) ? 1 : -1)
            .map(variation => (
              <React.Fragment key={variation.variationId}>
                <ListItem onClick={() => onChange(variation.variationId)} className={classes.variationContainer} disableGutters>
                  <Checkbox
                    checked={savedVariations.includes(variation.variationId)}
                    inputProps={{ 'aria-label': variation.name, 'data-cy': variation.variationId + '_checkbox' }}
                    color='primary'
                  />
                  <Typography variant='body1' className={classes.variantName}>
                    {variation.name}
                  </Typography>
                  <Typography variant='body1'>
                    {(variation.price > 0 ? '+' : '') + translator.toPrice(variation.price) + '€'}
                  </Typography>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

export default Variations
