import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

import Add from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import Remove from '@material-ui/icons/Remove'

const useStyles = makeStyles((theme) => ({
  selector: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 50,
  },
  iconButton: {
  },
}))

const QuantitySelector = ({
  props: { quantity, onChange = () => {}, onDelete = () => {}, deleteButton = true, disableButtons = false },
}) => {
  const classes = useStyles()

  const changeQuantity = (event, change) => {
    event.stopPropagation()
    const quantityChange = quantity + change
    if (typeof change === 'number' && quantityChange > 0)
      onChange({ quantityChange })
    else if (quantityChange <= 0)
      onDelete()
  }

  const removeDelivery = (event) => {
    event.stopPropagation()
    onDelete()
  }

  return (
    <div className={classes.selector}>
      <IconButton data-cy='removeOne' className={classes.iconButton} disabled={disableButtons || quantity <= 1} onClick={event => changeQuantity(event, -1)}><Remove /></IconButton>
      <div id='quantityValue'>{quantity}</div>
      <IconButton data-cy='addOne' className={classes.iconButton} disabled={disableButtons} onClick={event => changeQuantity(event, 1)}><Add /></IconButton>
      {deleteButton
        ? <IconButton className={classes.iconButton} disabled={disableButtons} onClick={event => removeDelivery(event)}><Delete /></IconButton>
        : null
      }
    </div>
  )
}

export default QuantitySelector
