import { getTotalPrice } from 'utility/pureFunctions'
import moment from 'moment'

export const formatCartProduct = ({
  name = '',
  price = 0,
  productId = null,
  savedVariations = [],
  variations = {},
  quantity,
  notes = '',
  warning = null,
  waitingTime = null,
}) => {
  return ({
    productId,
    name,
    variations: savedVariations
      .map(variationId => ({
        variationId,
        price: variations[variationId].price,
        name: variations[variationId].name,
      })),
    quantity: Number(quantity),
    notes,
    price: Number(price),
    warning,
    waitingTime,
  })
}

export const formatOrder = ({
  brandId,
  storeId,
  values,
  store,
  store: { storeProperties: { address = '', name = '', companyName = '' } = {} },
  cart,
  navigation: { modality = null, table = null, kiosk = false },
}) => {
  const a = moment().valueOf()
  const b = Math.ceil(Math.random() * 1000000)
  const orderId = (a * b)

  const { paymentMethod, deliveryDate, ...userData } = values

  const totalPrice = Math.round(getTotalPrice({ cart, storeId: store.storeId }) * 100) / 100

  let products = JSON.parse(JSON.stringify(cart))
  if (products.deliveryMethod.name !== 'delivery')
    delete products.deliveryMethod

  const clientData = Object.entries({ ...userData })
    .reduce((clientData, [target, value]) => {
      if (value != null)
        return { ...clientData, [target]: value }
      return clientData
    }, {})

  const storeString = `${name} (${companyName}), ${address}`

  return ({
    orderId,
    brandId,
    storeId,
    status: 'toBeSent',
    creationDate: new Date(),
    totalPrice: totalPrice,
    anonimized: false,
    paymentMethod,
    deliveryMethod: cart.deliveryMethod.name,
    deliveryDate: deliveryDate.toDate(),
    clientData,
    products,
    storeString,
    modality,
    table,
    kiosk,
  })
}

export const formatOrderForDatabase = ({
  order: { products, ...order },
  waitingTimes = [],
  navigation: { modality = 'takeAway' },
}) => ({
  ...order,
  products: Object.values(products)
    .map(product => ({
      ...product,
      waitingTime: modality !== 'orderAtTable' ? null : (waitingTimes[product.waitingTime] ?? null),
    }))
    .reduce((list, product) => ({ ...list, [product.cartProductId]: product }), {}),
})

export const formatError = ({ message = 'notFormattedError', name, code }) => {
  const error = new Error(message)
  if (name != null) error.name = name
  if (code != null) error.code = code
  return error
}

export const formatFirebaseError = ({ firebaseError }) => {
  if (firebaseError.name !== 'FirebaseError')
    return firebaseError

  const error = new Error(firebaseError.message)
  error.name = firebaseError.name
  error.code = firebaseError.code
  error.serverResponse = firebaseError.serverResponse
  return error
}
