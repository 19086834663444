/*
 * DIRECT actions
 */

export const REDUCE_EDIT_BRAND = 'REDUCE_EDIT_BRAND'

export const REDUCE_EDIT_CART = 'REDUCE_EDIT_CART'

export const REDUCE_EDIT_CATEGORIES = 'REDUCE_EDIT_CATEGORIES'

export const REDUCE_CREATE_ERROR = 'REDUCE_CREATE_ERROR'
export const REDUCE_DELETE_ERROR = 'REDUCE_DELETE_ERROR'

export const REDUCE_EDIT_NAVIGATION = 'REDUCE_EDIT_NAVIGATION'
export const REDUCE_DELETE_NAVIGATION = 'REDUCE_DELETE_NAVIGATION'

export const REDUCE_EDIT_ORDER = 'REDUCE_EDIT_ORDER'
export const REDUCE_EDIT_ORDER_PROPS = 'REDUCE_EDIT_ORDER_PROPS'

export const REDUCE_EDIT_PRODUCT_GROUPS = 'REDUCE_EDIT_PRODUCT_GROUPS'

export const REDUCE_EDIT_PRODUCTS = 'REDUCE_EDIT_PRODUCTS'
export const REDUCE_EDIT_PRODUCT = 'REDUCE_EDIT_PRODUCT'
export const REDUCE_EDIT_PRODUCT_IMAGE = 'REDUCE_EDIT_PRODUCT_IMAGE'

export const REDUCE_EDIT_STORES = 'REDUCE_EDIT_STORES'

export const REDUCE_EDIT_USER = 'REDUCE_EDIT_USER'

export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'

export const RESET = 'RESET'

/*
 * SAGA actions
 */

export const GET_BRAND = 'GET_BRAND'

export const GET_CART = 'GET_CART'

export const GET_PRODUCTS = 'GET_PRODUCTS'
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS'

export const GET_STORES = 'GET_STORES'

export const CREATE_CART_PRODUCT = 'CREATE_CART_PRODUCT'
export const EDIT_CART_PRODUCT = 'EDIT_CART_PRODUCT'
export const DELETE_CART_PRODUCT = 'DELETE_CART_PRODUCT'
export const DELETE_CART = 'DELETE_CART'

export const CREATE_ORDER = 'CREATE_ORDER'
export const CREATE_PAYMENT = 'CREATE_PAYMENT'

export const ERROR = 'ERROR'

export const START_ORDER_AT_TABLE = 'START_ORDER_AT_TABLE'
export const STOP_ORDER_AT_TABLE = 'STOP_ORDER_AT_TABLE'
export const WAIT_ORDER_AT_TABLE = 'WAIT_ORDER_AT_TABLE'
export const STOP_WAIT_ORDER_AT_TABLE = 'STOP_WAIT_ORDER_AT_TABLE'
export const CANCEL_ORDER_AT_TABLE = 'CANCEL_ORDER_AT_TABLE'

export const ON_START = 'ON_START'
export const READ_QR = 'READ_QR'
export const GO_TO_STORE_SELECTOR = 'GO_TO_STORE_SELECTOR'
export const GO_HOME = 'GO_HOME'
/*
 * SAGA Commands
 */

export const CMD_NO_CART_SAVED = 'CMD_NO_CART_SAVED'
