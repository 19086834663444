import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import translator from 'utility/translator'
import QuantitySelector from 'components/quantitySelector/QuantitySelector'
import WaitingTimesButton from 'components/waitingTimesButton/WaitingTimesButton.jsx'

import { useSize } from 'components/sizeSwitcher/SizeSwitcher'

import * as actions from 'redux/actions'

const useStyles = makeStyles((theme) => ({
  productContainer: {
    width: '100%',
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  productLineMobile: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  productLineDesktop: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '95%',
  },
  image: {
    height: 100,
    width: 100,
    borderRadius: 4,
    backgroundSize: 'cover',
    marginRight: theme.spacing(2),
  },
  productName: {
    flexGrow: 1,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    '& h6': {
      lineHeight: 1.1,
    },
  },
  productPropertiesMobile: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: '0px auto',
    alignItems: 'center',
  },
  productPropertiesDesktop: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: '0px auto',
    alignItems: 'center',
  },
  productPrice: {
    flexShrink: 0,
    marginLeft: 10,
  },
  quantityContainer: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  padding: {
    width: '100%',
    height: theme.spacing(2),
  },
  variationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    width: '60%',
  },
}))

const CartProduct = ({
  props: {
    mobile = false,
    cartProduct,
    cartProduct: { productId = '', cartProductId = '', notes = '', name, price = 0, quantity = 0, variations = [], warning = null },
    disableButtons = false,
  },
}) => {
  const history = useHistory()
  const classes = useStyles()
  const products = useSelector(state => state.products)
  const navigation = useSelector(state => state.navigation)
  const dispatch = useDispatch()
  const { brandId, storeId } = useParams()
  const product = products[productId] || {}
  const { width } = useSize()

  const selectProduct = () => {
    if (!disableButtons)
      history.push(`/${brandId}/${storeId}/products?productId=${productId}&cartProductId=${cartProductId}`)
  }

  const productImageUrl = products[productId] != null && products[productId].productImageUrl != null
    ? products[productId].productImageUrl : null

  const calculatedPrice = variations
    .reduce((price, variation) => price + Number(variation.price), product.price)

  return (
    <div id={productId} data-cy={productId + '_cartProductItem'} onClick={selectProduct} className={classes.productContainer}>
      <div className={mobile ? classes.productLineMobile : classes.productLineDesktop}>
        {productImageUrl != null && (mobile || width >= 1250)
          ? <div style={{ backgroundImage: `url(${productImageUrl})` }} className={classes.image} />
          : null}
        <div className={mobile ? classes.productPropertiesMobile : classes.productPropertiesDesktop}>
          <div className={classes.productName}>
            <div style={{ flex: '1 1 1px', height: 1 }} />
            <div style={{ textAlign: 'center' }}>
              <Typography variant='h6'>{name}</Typography>
            </div>
            <div style={{ flex: '1 1 1px', height: 1 }} />
            <div className={classes.productPrice}>
              <Typography variant='h6'>
                {translator.toPrice(calculatedPrice * quantity) + '€'}
              </Typography>
            </div>
            <div style={{ flex: '1 0 10px', height: 1 }} />
          </div>
          <div className={classes.quantityContainer}>
            <QuantitySelector props={{
              quantity,
              onChange: ({ quantityChange }) => dispatch({ type: actions.EDIT_CART_PRODUCT, payload: { cartProductId, change: { quantity: quantityChange } } }),
              onDelete: () => dispatch({ type: actions.DELETE_CART_PRODUCT, payload: { cartProductId } }),
              disableButtons,
            }}
            />
          </div>
          {warning !== null
            ? (
              <Typography variant='body1' style={{ color: 'red' }}>
                {translator.fromLabel(`cartProduct_${warning}_warning`)}
              </Typography>
              )
            : null}
        </div>
      </div>
      {navigation.modality === 'orderAtTable' ? <WaitingTimesButton props={{ cartProduct, disableButtons }} /> : null}
      <Typography>{notes}</Typography>
      <div style={{ display: variations.length === 0 ? 'none' : '' }} className={classes.padding} />
      {variations.map(variation => (
        <div key={variation.variationId} className={classes.variationContainer}>
          <Typography variant='body1'>
            {variation.name}
          </Typography>
          <Typography variant='body1'>
            {`(${(variation.price > 0 ? '+' : '')}${translator.toPrice(variation.price)}€)`}
          </Typography>
        </div>
      ))}
    </div>
  )
}

export default CartProduct
