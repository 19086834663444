import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Toolbar, Fab, Tooltip, AppBar } from '@material-ui/core'
import { Euro } from '@material-ui/icons'

import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getTotalPrice } from 'utility/pureFunctions'
import translator from 'utility/translator'

import * as actions from 'redux/actions'

const useStyles = makeStyles((theme) => ({
  desktopFab: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  mobileFab: {
    position: 'absolute',
    right: 25,
    bottom: 25,
    zIndex: 2000,
  },
  footerAppBar: {
    top: 'auto',
    bottom: 0,
  },
  footerToolBar: {
    minHeight: 52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: theme.values.maxWidth,
    margin: 'auto',
  },
}))

const Footer = ({ props: { fields, values, errors = {}, mobile = false, speedOrder = false } }) => {
  const classes = useStyles()
  const cart = useSelector(state => state.cart)
  const dispatch = useDispatch()
  const [blockButton, setBlockButton] = useState(false)
  const loading = useSelector(state => state.loading)
  const { brandId, storeId } = useParams()
  const store = useSelector(state => state.stores[storeId])
  const navigation = useSelector(state => state.navigation)

  useEffect(() => {
    if (blockButton) {
      const timeout = setTimeout(() => setBlockButton(false), 1000)
      return () => {
        clearTimeout(timeout)
      }
    }
    return () => {}
  }, [blockButton, setBlockButton])

  const checkCompletion = () => (
    (navigation.modality !== 'orderAtTable' && typeof store?.minOrder === 'number' && getTotalPrice({ cart }) < store.minOrder) ||
    cart.deliveryMethod == null || cart.deliveryMethod.productId == null ||
    values.paymentMethod == null || values.paymentMethod === '' ||
    values.deliveryDate == null ||
    Object.values(errors).filter(error => error != null).length > 0 ||
    (!speedOrder && fields
      .filter(({ label, required }) =>
        (required && (values[label] == null || values[label] === '')))
      .length > 0)
  )

  const order = () => {
    setBlockButton(true)

    dispatch({
      type: actions.CREATE_ORDER,
      payload: { values, brandId, storeId, mobile },
    })
  }

  if (mobile)
    return (
      <>
        <AppBar position='fixed' color='secondary' className={classes.footerAppBar}>
          <div className={classes.footer}>
            <Toolbar className={classes.footerToolBar}>
              <div className={classes.mobileFab}>
                <Tooltip title={translator.fromLabel('order_order_button')} placement='top'>
                  <div>
                    <Fab
                      disabled={blockButton || loading.length > 0 || checkCompletion()}
                      onClick={order}
                      color='primary'
                      data-cy='finalizeOrder'
                      aria-label='order'
                    >
                      <Euro />
                    </Fab>
                  </div>
                </Tooltip>
              </div>
            </Toolbar>
          </div>
        </AppBar>
      </>
    )
  else
    return (
      <div className={classes.desktopFab}>
        <Tooltip title={translator.fromLabel('order_order_button')} placement='top'>
          <Toolbar style={{ width: '100%' }}>
            <Button
              disabled={blockButton || loading.length > 0 || checkCompletion()}
              onClick={order}
              color='primary'
              data-cy='finalizeOrder'
              aria-label='order'
              style={{ width: '100%' }}
              variant='contained'
            >
              <Euro />
            </Button>
          </Toolbar>
        </Tooltip>
      </div>
    )
}

export default Footer
