import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import Popper from '@material-ui/core/Popper'
import TextField from '@material-ui/core/TextField'

import Autocomplete from '@material-ui/lab/Autocomplete'

import Modal from 'components/modal/Modal'
import translator from 'utility/translator'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    padding: 10,
    textAlign: 'center',
  },
  button: {
    margin: 10,
  },
  phone: {
    color: theme.palette.error.main,
    textAlign: 'center',
  },
  divider: {
    width: '90%',
    height: 1,
    backgroundColor: theme.palette.secondary.contrastText,
    margin: '20px 5%',
  },
  zipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  zipButton: {
    width: '40%',
    margin: 10,
  },
}))

const DeliveryModal = ({ props: { multipleStores = false, store, onSelect = () => {}, goToStoresSelector = () => {}, setModal = () => {} } = {} }) => {
  const classes = useStyles()
  const [value, setValue] = useState('')

  return (
    <Modal>
      <div className={classes.container}>
        <Typography variant='h4' className={classes.title}>{translator.fromLabel('welcomeModal_deliveryModal_title')}</Typography>
        <div className={classes.zipContainer}>
          <Autocomplete
            id='zipCode'
            value={value}
            options={store.zipCodes}
            getOptionLabel={option => option}
            onChange={(event, value) => setValue(value)}
            style={{ width: 150, margin: 10 }}
            autoHighlight
            autoSelect
            PopperComponent={props => <Popper {...props} style={{ width: 150, zIndex: 4000 }} placement='bottom-start' />}
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{ ...inputProps, 'data-cy': 'zipCode' }}
                label={translator.fromLabel('welcomeModal_deliveryModal_textfield_label')}
              />
            )}
          />
        </div>
        <div className={classes.divider} />
        <Toolbar disableGutters>
          <Button
            data-cy='welcomeModal_goBack_button'
            className={classes.button}
            variant='outlined'
            color='primary'
            onClick={() => setModal('firstModal')}
          >
            {translator.fromLabel('welcomeModal_goBack_button')}
          </Button>
          {multipleStores
            ? (
              <Button
                data-cy='welcomeModal_zipCodeError_button'
                className={classes.button}
                variant='outlined'
                color='primary'
                onClick={goToStoresSelector}
              >
                {translator.fromLabel('welcomeModal_zipCodeError_button')}
              </Button>
              ) : null}
          <Button
            disabled={value == null || value === ''}
            data-cy='welcomeModal_goBack_button'
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={() => onSelect({ modality: 'delivery', param: value })}
          >
            {translator.fromLabel('welcomeModal_confirm_button')}
          </Button>
        </Toolbar>
      </div>
    </Modal>
  )
}

export default DeliveryModal
