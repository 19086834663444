import { put, takeEvery, all } from 'redux-saga/effects'
import * as actions from 'redux/actions'
import { history } from 'index'
import { errorHandler } from 'index.js'

import uniqid from 'uniqid'

function * watchCreateError() {
  yield takeEvery(actions.ERROR, createError)
}

// eslint-disable-next-line
const code = {
  101: 'Generic Brand error.',
  102: 'Brand not existent, not active or forbidden.',
  103: 'Brand photos not found.',
  201: 'Generic Store error.',
  202: 'Store not existent, not active or forbidden.',
  301: 'Generic products error.',
  302: 'Products per store error',
  303: 'Product photos non found.',
  304: 'Product deleted in database',
  305: 'Product modified in database',
  402: 'Order timeout error.',
  501: 'Generic QR error',
  502: 'QR code error: brand id mismatch',
  503: 'QR code error: store id mismatch',
}

function * createError({ payload: { error, error: { message = '', name = '', stack, code } } }) {
  try {
    error.errorId = uniqid()
    errorHandler.report(error)

    switch (code) {
      case 'unavailable':
        window.location.reload(true)
        break
      case 101:
      case 102:
      case 201:
        console.error(error)
        if (process.env.REACT_APP_ENV !== 'production')
          yield put({ type: actions.REDUCE_CREATE_ERROR, payload: { error } })
        history.push('/')
        break
      case 202:
      case 302:
        yield put({ type: actions.GO_TO_STORE_SELECTOR })
        if (process.env.REACT_APP_ENV !== 'production')
          yield put({ type: actions.REDUCE_CREATE_ERROR, payload: { error } })
        break
      case 103:
      case 303:
        break
      case 501:
        yield put({ type: actions.REDUCE_CREATE_ERROR, payload: { error: new Error('QR code not recognized') } })
        break
      case 502:
        yield put({ type: actions.REDUCE_CREATE_ERROR, payload: { error: new Error('QR code error: brand id mismatch') } })
        break
      case 503:
        yield put({ type: actions.REDUCE_CREATE_ERROR, payload: { error: new Error('QR code error: store id mismatch') } })
        break
      default:
        console.error(error)
        yield put({ type: actions.REDUCE_CREATE_ERROR, payload: { error } })
        break
    }
  } catch (error) {
    console.log(error)
  }
}

export default function * errorsSagas() {
  yield all([
    watchCreateError(),
  ])
}
