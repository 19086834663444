import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'

import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'

import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'redux/actions'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import translator from 'utility/translator'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    maxWidth: theme.values.componentsMaxWidth,
    margin: 'auto',
  },
  warning: {
    paddingTop: 30,
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
    paddingTop: 30,
  },
}))

const StoreSelector = props => {
  const state = useSelector(state => state)
  const history = useHistory()
  const { brandId } = useParams()
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: actions.REDUCE_DELETE_NAVIGATION })
    if (Object.keys(state.stores || {}).length === 1)
      history.push(`/${brandId}/${Object.keys(state.stores)[0]}`)
  }, [brandId, state.stores])

  return (
    <>
      {Object.values(state.stores || {}).length > 0
        ? (
          <>
            <div className={classes.title}>
              <Typography variant='h5'>{translator.fromLabel('storeSelect_title')}</Typography>
            </div>
            <StoresList />
          </>
          ) : state.brand.brandId != null && state.loading.length === 0
            ? (
              <div className={classes.warning}>
                <Typography variant='h4'>{translator.fromLabel('storeSelector_noActiveStores_warning')}</Typography>
              </div>
              )
            : null}
    </>
  )
}

const StoresList = () => {
  const state = useSelector(state => state)
  const location = useLocation()
  const classes = useStyles()

  // const [searchParam, setSearchParam] = useState('')
  const urlParams = new URLSearchParams(location.search)
  const searchParam = urlParams.get('search') || ''

  const storesList = Object.values(state.stores || {})
    .filter(store => searchParam === '' ||
      (store.zipCodes || []).includes(searchParam) ||
      (store.storeProperties != null && store.storeProperties.name.toLowerCase().includes(searchParam.toLowerCase())))

  return (
    <List className={classes.mainContainer}>
      <Divider />
      {storesList.map((store, index) => {
        return (
          <React.Fragment key={store.storeId}>
            <StoreItem props={{ store }} />
            <Divider />
          </React.Fragment>
        )
      })}
    </List>
  )
}

const useStylesStoreItem = makeStyles((theme) => ({
  storeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0),
    cursor: 'pointer',
  },
  storeProperties: {

  },
  storeName: {
    color: theme.palette.primary.main,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    color: theme.palette.primary.main,
  },
}))

const StoreItem = ({ props: { store: { storeId, storeProperties = {}, services = [], zipCodes = [] } } }) => {
  const history = useHistory()
  const classes = useStylesStoreItem()
  const { brandId } = useParams()

  const selectStore = () => {
    history.push(`/${brandId}/${storeId}`)
  }

  const servicesList = Object.keys(services)
    .filter(service => !service.active)
    .map(service => ' ' + translator.fromLabel(`storeSelector_${service}`))
    .join(', ')

  return (
    <ListItem data-cy={'storeItem_' + storeId} className={classes.storeContainer} disableGutters onClick={selectStore}>
      <div className={classes.storeProperties}>
        <div className={classes.storeName}>
          <Typography variant='h6'>
            {storeProperties.name || storeId}
          </Typography>
        </div>
        <Typography variant='body2'>
          {storeProperties.address}
        </Typography>
        <Typography variant='body2'>
          {`${translator.fromLabel('storeSelector_services')}: ${servicesList}`}
        </Typography>
        {services.delivery != null && zipCodes.length > 0
          ? (
            <Typography variant='body2'>
              {`${translator.fromLabel('storeSelector_zipCodes')}: ${(zipCodes).join(', ')}`}
            </Typography>
            ) : null}
      </div>
      <IconButton className={classes.icon}>
        <ArrowForwardIos />
      </IconButton>
    </ListItem>
  )
}

export default StoreSelector
