import { combineReducers } from 'redux'

import * as actions from './actions'
import * as initialState from './initialState'

function brand(state = {}, { type, payload }) {
  switch (type) {
    case actions.REDUCE_EDIT_BRAND:
      return payload
    default:
      return state
  }
}

function categories(state = {}, { type, payload }) {
  switch (type) {
    case actions.REDUCE_EDIT_CATEGORIES:
      return payload
    default:
      return state
  }
}

function cart(state = {}, { type, payload }) {
  switch (type) {
    case actions.REDUCE_EDIT_CART:
      return payload
    default:
      return state
  }
}

function error(state = {}, { type, payload: { error: { errorId, message } = {} } = {} }) {
  switch (type) {
    case actions.REDUCE_CREATE_ERROR:
      return { ...state, [errorId]: { message, errorId } }
    case actions.REDUCE_DELETE_ERROR:
      let newState = { ...state }
      delete newState[errorId]
      return newState
    default:
      return state
  }
}

function loading(state = [], action) {
  switch (action.type) {
    case actions.START_LOADING:
      return [...state, action.payload.action]
    case actions.STOP_LOADING:
      return state.filter(x => x !== action.payload.action)
    default:
      return state
  }
}

function navigation(state = {}, { type, payload }) {
  switch (type) {
    case actions.REDUCE_EDIT_NAVIGATION:
      return { ...state, ...payload }
    case actions.REDUCE_DELETE_NAVIGATION:
      return {}
    default:
      return state
  }
}

function order(state = {}, { type, payload }) {
  switch (type) {
    case actions.REDUCE_EDIT_ORDER:
      return payload
    case actions.REDUCE_EDIT_ORDER_PROPS:
      return { ...state, ...payload }
    case actions.DELETE_CART:
      return {}
    default:
      return state
  }
}

function productGroups(state = {}, { type, payload }) {
  switch (type) {
    case actions.REDUCE_EDIT_PRODUCT_GROUPS:
      return payload
    default:
      return state
  }
}

// eslint-disable-next-line no-unreachable-loop
const checkObjectFullness = obj => { for (let prop in obj) return true; return false }

function products(state = {}, { type, payload }) {
  let newState
  switch (type) {
    case actions.REDUCE_EDIT_PRODUCTS:
      if (checkObjectFullness(state)) {
        newState = {}
        for (let productId in payload)
          newState[productId] = { ...(state[productId] || {}), ...payload[productId] }
        return newState
      }

      return payload
    case actions.REDUCE_EDIT_PRODUCT:
      newState = { ...state }
      const { productId, ...properties } = payload
      newState[productId] = { ...newState[productId], ...properties }
      return newState
    case actions.REDUCE_EDIT_PRODUCT_IMAGE:
      if (state[payload.productId] != null) {
        const { productId, ...properties } = payload
        newState = { ...state }
        newState[productId] = { ...newState[productId], ...properties }
        return newState
      } else {
        return state
      }
    default:
      return state
  }
}

function stores(state = {}, { type, payload }) {
  switch (type) {
    case actions.REDUCE_EDIT_STORES:
      return payload
    default:
      return state
  }
}

function user(state = { ...initialState.user }, action) {
  switch (action.type) {
    case actions.REDUCE_EDIT_USER:
      return action.payload
    default:
      return state
  }
}

const rootReducer = combineReducers({
  brand,
  cart,
  categories,
  error,
  loading,
  navigation,
  order,
  productGroups,
  products,
  stores,
  user,
})

export default rootReducer
