import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Modal from 'components/modal/Modal'
import translator from 'utility/translator'
import QrReader from 'react-qr-reader'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    padding: 10,
    textAlign: 'center',
  },
  button: {
    margin: 10,
  },
  phone: {
    color: theme.palette.error.main,
    textAlign: 'center',
  },
  divider: {
    width: '90%',
    height: 1,
    backgroundColor: theme.palette.secondary.contrastText,
    margin: '20px 5%',
  },
  zipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  zipButton: {
    width: '40%',
    margin: 10,
  },
}))

const OrderAtTableModal = ({ props: { brand = {}, store, setModal = () => {}, onSelect = () => {} } = {} }) => {
  const classes = useStyles()
  const [scanned, setScanned] = React.useState('')

  const handleScan = param => {
    if (process.env.REACT_APP_ENV !== 'production')
      setScanned(String(param))

    if (param)
      onSelect({ modality: 'orderAtTable', param })
  }

  const handleError = err => {
    console.error(err)
  }

  return (
    <Modal>
      <div className={classes.container}>
        <Typography variant='h4' className={classes.title}>{translator.fromLabel('welcomeModal_orderAtTable_title')}</Typography>
        <Typography variant='h6' className={classes.title}>{translator.fromLabel('welcomeModal_orderAtTable_text')}</Typography>
        <QrReader
          delay={1000}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
        <Typography variant='body1'>{scanned ?? ' '}</Typography>
        <Button data-cy='welcomeModal_goBack_button' className={classes.button} variant='outlined' color='primary' onClick={() => setModal('firstModal')}>
          {translator.fromLabel('welcomeModal_goBack_button')}
        </Button>
      </div>
    </Modal>
  )
}

export default OrderAtTableModal
