import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { AppBar, Button, Typography, Tooltip, Fab, Toolbar } from '@material-ui/core'

import Euro from '@material-ui/icons/Euro'

import translator from 'utility/translator'

const useStyles = makeStyles((theme) => ({
  fab2: {
    position: 'absolute',
    right: 25,
    bottom: 25,
    zIndex: 2000,
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    paddingBottom: 10,
    backgroundColor: theme.palette.background.default,
  },
  mobileAppBar: {
    top: 'auto',
    bottom: 0,
  },
  desktopAppBar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    marginTop: 10,
    borderRadius: theme.shape.borderRadius,
  },
  footerWarning: {
    maxWidth: theme.values.maxWidth,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    bottom: 75,
    right: 'auto',
    padding: 10,
    borderRadius: 4,
  },
  totalPrice: {
    minHeight: 52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: theme.values.maxWidth,
    margin: 'auto',
  },
}))

const Footer = ({ props: { totalPrice, buttonDisabled, goToOrder, mobile = true, view } }) => {
  const classes = useStyles()

  if (mobile)
    return (
      <>
        {buttonDisabled != null && buttonDisabled !== ''
          ? (
            <div style={{ position: 'fixed' }} className={classes.footerWarning} data-cy='footerWarning'>
              <Typography variant='body1'>
                {buttonDisabled}
              </Typography>
            </div>
            ) : null}
        <AppBar position='fixed' color='secondary' className={classes.mobileAppBar}>
          <div className={classes.footer}>
            <Toolbar className={classes.totalPrice}>
              <Typography variant='h6'>{translator.fromLabel('cart_totalPrice_label')}</Typography>
              <div style={{ width: 10 }} />
              <Typography variant='h6'>{`${translator.toPrice(totalPrice)}€`}</Typography>
              <div style={{ flex: '1 1' }} />
              <div className={classes.fab2}>
                <Tooltip title={translator.fromLabel('cart_order_button')} placement='top'>
                  <div>
                    <Fab disabled={buttonDisabled != null} onClick={goToOrder} color='primary' data-cy='order'>
                      <Euro />
                    </Fab>
                  </div>
                </Tooltip>
              </div>
            </Toolbar>
          </div>
        </AppBar>
      </>
    )
  return (
    <div className={classes.footer}>
      {view !== 'waitOrder' && buttonDisabled != null && buttonDisabled !== ''
        ? (
          <div className={classes.footerWarning} data-cy='footerWarning'>
            <Typography variant='body1'>
              {buttonDisabled}
            </Typography>
          </div>
          ) : null}
      {view !== 'waitOrder' && buttonDisabled == null
        ? (
          <Button
            color='primary'
            variant='contained'
            disabled={buttonDisabled != null}
            onClick={goToOrder}
            data-cy='order'
            style={{ width: '100%' }}
          >
            <Euro />
            <div style={{ width: '10%' }} />
            {translator.fromLabel('cart_desktopOrder_button')}
          </Button>
          ) : null}
      <div className={classes.desktopAppBar}>
        <Toolbar className={classes.totalPrice}>
          <Typography variant='h6'>{translator.fromLabel('cart_totalPrice_label')}</Typography>
          <div style={{ width: 10 }} />
          <Typography variant='h6'>{`${translator.toPrice(totalPrice)}€`}</Typography>
          <div style={{ flex: '1 1' }} />
        </Toolbar>
      </div>
    </div>
  )
}

export default Footer
