import { put, takeEvery, select } from 'redux-saga/effects'
import * as actions from 'redux/actions'
import { history } from 'index'

const urlRegex = /^https:\/\/mytec-ecommerce(-development|).web.app\/[0-9a-z]+\/[0-9a-z]+/

function * onStart() {
  yield put({ type: actions.START_LOADING, payload: { action: 'onStart' } })
  try {
    const brandId = history.location.pathname.split('/')[1]

    if (brandId == null || brandId === '') {
      history.push('/')
      yield put({ type: actions.REDUCE_EDIT_NAVIGATION, payload: { modality: 'qrReader' } })
    } else {
      const storeId = history.location.pathname.split('/')[2]
      const search = history.location.search

      let sanitizedPath = '' +
        (brandId != null ? `/${brandId.toLowerCase()}` : '') +
        (storeId != null ? `/${storeId.toLowerCase()}` : '')

      history.push(sanitizedPath)

      yield put({ type: actions.GET_BRAND, payload: { brandId, storeId, search } })
    }
  } catch (error) {
    history.push('/')
    yield put({ type: actions.REDUCE_EDIT_NAVIGATION, payload: { modality: 'qrReader' } })
    console.error(error)
  }
  yield put({ type: actions.STOP_LOADING, payload: { action: 'onStart' } })
}

function * readQR({ payload }) {
  yield put({ type: actions.START_LOADING, payload: { action: 'readQR' } })
  try {
    if (typeof payload !== 'string')
      throw new Error('QR not recognized')

    const url = payload.match(urlRegex)

    if (url == null || url[0] == null)
      throw new Error('QR url not recognized')

    const brandId = url[0].replace(/^https:\/\/mytec-ecommerce(-development|).web.app\//, '').replace(/\/[0-9a-z]+/, '')
    const storeId = url[0].replace(/^https:\/\/mytec-ecommerce(-development|).web.app\/[0-9a-z]+\//, '')

    history.push(`/${brandId}/${storeId}`)
    yield put({ type: actions.REDUCE_DELETE_NAVIGATION })
    yield put({ type: actions.GET_BRAND, payload: { brandId, storeId } })
    if (payload.search(/orderAtTable=/) !== -1)
      yield put({ type: actions.START_ORDER_AT_TABLE, payload: { brandId, storeId, param: payload } })
  } catch (error) {
    yield put({ type: actions.ERROR, payload: { error } })
  }
  yield put({ type: actions.STOP_LOADING, payload: { action: 'readQR' } })
}

function * goToStoreSelector() {
  yield put({ type: actions.START_LOADING, payload: { action: 'goToStoreSelector' } })
  try {
    const brand = yield select(state => state.brand)
    if (brand.brandId == null)
      throw new Error('Cannot find brandId in state')

    history.push(`/${brand.brandId}`)
    yield put({ type: actions.REDUCE_DELETE_NAVIGATION })
    yield put({ type: actions.REDUCE_EDIT_PRODUCTS, payload: {} })
    yield put({ type: actions.REDUCE_EDIT_CART, payload: {} })
  } catch (error) {
    console.log(error)
  }
  yield put({ type: actions.STOP_LOADING, payload: { action: 'goToStoreSelector' } })
}

function * goHome({ payload: { brandId, storeId } = {} }) {
  try {
    if (brandId == null || storeId == null)
      return false

    history.push(`/${brandId}/${storeId}`)
  } catch (error) {
    console.log(error)
  }
}

export default function * navigationSagas() {
  yield takeEvery(actions.ON_START, onStart)
  yield takeEvery(actions.READ_QR, readQR)
  yield takeEvery(actions.GO_TO_STORE_SELECTOR, goToStoreSelector)
  yield takeEvery(actions.GO_HOME, goHome)
}
