import React from 'react'

import { InputBase, IconButton } from '@material-ui/core'
import { fade, makeStyles } from '@material-ui/core/styles'

import { Clear, Search } from '@material-ui/icons'

import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Sidebar from './Sidebar'

import translator from 'utility/translator'

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    margin: 5,
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
  },
  clearIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  clearButton: {
    padding: 0,
    borderRadius: 0,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 0),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}))

const updateParams = ({ target, value, history, location, pathname }) => {
  let currentUrlParams = new URLSearchParams(location.search)

  if (value !== '')
    currentUrlParams.set(target, value)
  else
    currentUrlParams.delete(target)
  currentUrlParams.delete('productId')

  if (pathname != null)
    history.push({ pathname, search: currentUrlParams.toString() })
  else
    history.push({ search: currentUrlParams.toString() })
}

const DesktopMenu = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { brandId, storeId } = useParams()
  const stores = useSelector(state => state.stores)

  let currentUrlParams = new URLSearchParams(location.search)
  let value = currentUrlParams.get('search') || ''

  if (Object.keys(stores || {}).length === 0)
    return null

  return (
    <div className={classes.container}>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <Search />
        </div>
        <InputBase
          value={value}
          placeholder={translator.fromLabel(`menu_search${storeId != null ? 'Product' : 'Store'}_placeholder`)}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={event => updateParams({ target: 'search', value: event.target.value, history, location, pathname: storeId != null ? `/${brandId}/${storeId}` : `/${brandId}` })}
        />
        <div className={classes.clearIcon}>
          <IconButton
            onClick={() => updateParams({ target: 'search', value: '', history, location })}
            className={classes.clearButton}
            color='inherit'
          >
            <Clear />
          </IconButton>
        </div>
      </div>
      <Sidebar />
    </div>
  )
}

export default DesktopMenu
