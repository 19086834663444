import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'

import Clear from '@material-ui/icons/Clear'

import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'redux/actions'

import translator from 'utility/translator'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: 32,
    maxHeight: 32,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}))

const OrderAtTableWarning = () => {
  const classes = useStyles()
  const { table, kiosk } = useSelector(state => state.navigation)
  const dispatch = useDispatch()
  const { brandId, storeId, view } = useParams()

  const onClick = () => {
    dispatch({ type: actions.STOP_ORDER_AT_TABLE, payload: { brandId, storeId } })
  }

  return (
    <Toolbar className={classes.toolbar}>
      <div style={{ flex: '1 1 10px' }} />
      {translator.fromLabel('orderAtTable_band_title') + table}
      <div style={{ flex: '1 1 10px' }} />
      {view !== 'waitOrder' && !kiosk
        ? (
          <IconButton disableRipple color='secondary' onClick={onClick}>
            <Clear />
          </IconButton>
          ) : null}
    </Toolbar>
  )
}

export default OrderAtTableWarning
