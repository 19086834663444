import React, { memo } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'

import AddShoppingCart from '@material-ui/icons/AddShoppingCart'
import Check from '@material-ui/icons/Check'

import { useLocation } from 'react-router-dom'

import QuantitySelector from 'components/quantitySelector/QuantitySelector'

import translator from 'utility/translator'

const useStylesProductItem = makeStyles((theme) => ({
  productImage: {
    width: 200,
    height: 200,
    marginBottom: 20,
    borderRadius: 4,
    position: 'relative',
    backgroundSize: 'cover',
    boxShadow: `0px 2px 1px -1px ${theme.palette.boxShadow.primary}, 0px 1px 1px 0px ${theme.palette.boxShadow.secondary}, 0px 1px 3px 0px ${theme.palette.boxShadow.tertiary}`,
  },
  priceContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    position: 'absolute',
    right: -15,
    bottom: -15,
    borderRadius: '50%',
    padding: 5,
    boxShadow: '-1px 1px 3px 1px rgba(0,0,0,.4)',
  },
  priceContainer02: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    borderRadius: '100%',
    boxShadow: '-1px 1px 3px 1px rgba(0,0,0,.4)',
    padding: 5,
    '&::after': {
      content: '""',
      display: 'block',
      paddingTop: '100%',
    },
  },
  price: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  name: {
    textAlign: 'center',
  },
  shortDescr: {
    textAlign: 'justify',
    margin: '0px 10px',
  },
  notes: {
    maxWidth: '100%',
    width: '100%',
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'center',
  },
  description: {
    width: '100%',
    maxWidth: theme.values.componentsMaxWidth,
    margin: '20px 0px',
    textAlign: 'justify',
    padding: '0px 5%',
    whiteSpace: 'pre-wrap',
  },
  productProperties: {
    margin: 10,
    display: 'flex',
    justifyContent: 'space-around',
    position: 'relative',
    paddingRight: 75,
  },
  fab: {
    margin: 10,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  variationsPanel: {
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
}))

export const ProductHeader = memo(({
  props: { name, productImageUrl, calculatedPrice },
}) => {
  const classes = useStylesProductItem()
  return (
    <>
      {productImageUrl
        ? (
          <div
            className={classes.productImage}
            style={{ backgroundImage: `url(${productImageUrl})` }}
          >
            <Fab disableFocusRipple disable disableRipple color='primary' style={{ cursor: 'default', position: 'absolute', bottom: -15, right: -15 }}>
              <Typography variant='body1'>
                {`${translator.toPrice(calculatedPrice)}€`}
              </Typography>
            </Fab>
          </div>
          ) : null}
      <div className={classes.productProperties}>
        <Typography variant='h4' className={classes.name}>
          {name}
        </Typography>
        {productImageUrl == null
          ? (
            <Fab disableRipple color='primary' style={{ position: 'absolute', bottom: 0, right: 0 }}>
              <Typography variant='body1'>
                {`${translator.toPrice(calculatedPrice)}€`}
              </Typography>
            </Fab>
            ) : null}
      </div>
    </>
  )
}, (p, n) => p.props.productImageUrl === n.props.productImageUrl && p.props.calculatedPrice === n.props.calculatedPrice)

export const ProductActions = memo(({ props: { quantity, setQuantity, notes, setNotes, createCartProduct } }) => {
  const classes = useStylesProductItem()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const cartProductId = urlParams.get('cartProductId')

  return (
    <>
      <div className={classes.notes}>
        <TextField
          id='notes'
          label={translator.fromLabel('productItem_notes_label')}
          value={notes}
          data-cy='notes'
          onChange={event => setNotes(event.target.value)}
          style={{ width: '90%' }}
          multiline
        />
      </div>
      <div className={classes.actions}>
        <QuantitySelector props={{
          quantity,
          onChange: ({ quantityChange }) => setQuantity(quantityChange),
          deleteButton: false,
        }}
        />
        <Tooltip title={translator.fromLabel('productItem_addProduct_button')} placement='top'>
          <Fab color='primary' onClick={createCartProduct} className={classes.fab} aria-label='add'>
            {cartProductId != null ? <Check /> : <AddShoppingCart />}
          </Fab>
        </Tooltip>
      </div>
    </>
  )
})

export const Description = memo(({ props: { description } }) => {
  const classes = useStylesProductItem()
  return (
    <Typography className={classes.description} variant='body1'>
      {description}
    </Typography>
  )
})
