import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
}))

const BrandHeader = () => {
  const classes = useStyles()
  const brand = useSelector(state => state.brand)
  const { view } = useParams()

  if (view != null)
    return null
  return (
    <div className={classes.container}>
      <Typography variant='body1' style={{ textAlign: 'justify', whiteSpace: 'pre-wrap' }}>
        {brand.description || ''}
      </Typography>
    </div>
  )
}

export default BrandHeader
