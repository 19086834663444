import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, TextField } from '@material-ui/core'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import DeliveryMethod from './components/DeliveryMethod.jsx'
import Footer from './components/Footer'
import PaymentMethods from './components/PaymentMethods'
import TimeChooser from './components/TimeChooser'
import TimeSlots from './components/TimeSlots'

import translator from 'utility/translator'
import moment from 'moment'

import { useSize } from 'components/sizeSwitcher/SizeSwitcher'

const useStyles = makeStyles((theme) => ({
  orderContainer: {
    width: '100%',
    margin: 'auto',
    paddingTop: 20,
    paddingBottom: 100,
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const startingValues = {
  name: '',
  surname: '',
  phone: '',
  email: '',
  address: '',
  city: '',
  zip: '',
  notes: '',
}

const Order = () => {
  const cart = useSelector(state => state.cart)
  const loading = useSelector(state => state.loading)
  const order = useSelector(state => state.order)
  const user = useSelector(state => state.user)
  const navigation = useSelector(state => state.navigation)
  const [values, setValues] = useState({ ...startingValues })
  const [errors, setErrors] = useState({})
  const classes = useStyles()
  const { storeId } = useParams()
  const { mobile } = useSize()

  const speedOrder = navigation.modality === 'orderAtTable' &&
    values.paymentMethod === 'cashOn'

  useEffect(() => {
    setValues({ ...values, zip: user.zip ?? '' })
  }, [user.zip])

  useEffect(() => {
    if (order.storeId === storeId)
      setValues({
        ...order.clientData || {},
        deliveryDate: moment(order.deliveryDate),
        paymentMethod: order.paymentMethod,
      })
  }, [order, storeId])

  useEffect(() => {
    if (navigation.modality === 'orderAtTable')
      setValues({ ...values, deliveryDate: moment() })
  }, [navigation.modality])

  const fields = [
    { label: 'name', required: true },
    { label: 'surname', required: true },
    { label: 'email', required: true, type: 'email' },
    { label: 'phone', required: true, type: 'phone' },
  ]

  if (Object.keys(cart).filter(cartProductId => cartProductId !== 'deliveryMethod').length === 0 && loading.length === 0)
    return <OrderErrorMessage />

  return (
    <div className={classes.orderContainer}>
      {!speedOrder ? <Fields props={{ fields, values, setValues, errors, setErrors }} /> : null}
      <PaymentMethods props={{ values, setValues, errors, setErrors }} />
      <DeliveryMethod props={{ values, setValues }} />
      <Footer props={{ fields, values, errors, mobile, speedOrder }} />
    </div>
  )
}

const OrderErrorMessage = () => {
  const classes = useStyles()
  return (
    <div className={classes.errorContainer}>
      <div>
        <Typography variant='h5'>{translator.fromLabel('order_cartEmpty_error')}</Typography>
      </div>
    </div>
  )
}

const Fields = ({ props: { fields, values, setValues, errors, setErrors } }) => {
  const classes = useStyles()
  const loading = useSelector(state => state.loading)
  const navigation = useSelector(state => state.navigation)
  const { storeId } = useParams()
  const store = useSelector(state => state.stores[storeId])

  const onChange = ({ type, label, value = '', required }) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const phoneRegex = /^[0-9]{2,5}[/ -]*[0-9]{5,11}$/

    if (type === 'email' && value !== '' && !value.match(emailRegex))
      setErrors({ ...errors, [label]: 'order_emailNotValid_error' })
    else if (type === 'phone' && value !== '' && !value.match(phoneRegex))
      setErrors({ ...errors, [label]: 'order_phoneNotValid_error' })
    else if (required && (value === '' || value == null))
      setErrors({ ...errors, [label]: 'order_fieldRequired_error' })
    else
      setErrors({ ...errors, [label]: null })

    setValues({ ...values, [label]: value })
  }

  return (
    <div className={classes.fieldsContainer}>
      <Typography variant='h5'>
        {translator.fromLabel('order_fieldsTitle')}
      </Typography>
      {fields.map(({ label, required, type = 'text', disabled }) => (
        <TextField
          key={label}
          id={label}
          label={translator.fromLabel(`order_${label}Textfield_label`)}
          value={values[label]}
          onChange={event => onChange({ type, label, value: event.target.value, required })}
          onBlur={() => onChange({ type, label, value: values[label], required })}
          style={{ marginTop: 10 }}
          type={type}
          error={errors[label] != null}
          helperText={translator.fromLabel(errors[label]) || ''}
          required={required}
          disabled={loading.length > 0}
          InputLabelProps={type === 'time' ? { shrink: true } : {}}
        />
      ))}
      <TimeChooser props={{ navigation, values, setValues, errors, setErrors, loading, store }} />
      <TimeSlots props={{ store, date: moment() }} />
    </div>
  )
}

export default Order
