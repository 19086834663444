import { put, takeEvery, all } from 'redux-saga/effects'
import * as actions from 'redux/actions'
import moment from 'moment'
import { formatError, formatFirebaseError } from 'redux/format'

import firebase from 'redux/firebase/firebase'

export function * watchGetBrand() {
  yield takeEvery(actions.GET_BRAND, getBrand)
}

function checkAmbientAndBrands({ brand }) {
  if (process.env.REACT_APP_ENV !== 'production' && !brand.testingEnabled)
    throw formatError({ message: `Development branch. Access to brand "${brand.brandId}" forbidden`, code: 101 })
  else if (process.env.REACT_APP_ENV === 'production' && brand.testingEnabled)
    throw formatError({ message: `Production branch. Access to brand "${brand.brandId}" forbidden`, code: 101 })
}

export function * getBrand({ payload: { brandId = '', storeId, search } }) {
  yield put({ type: actions.START_LOADING, payload: { action: 'getBrand' } })
  try {
    let brand = yield firebase.getCollectionDoc({ collectionId: 'Brands', docId: brandId })
    checkAmbientAndBrands({ brand })

    if (brand == null) {
      throw formatError({ message: `BrandId '${brandId}' not valid or not found`, code: 101 })
    } else if (brand.enabled !== true) {
      throw formatError({ message: `Brand '${brandId}' not active`, code: 101 })
    } else {
      if (brand.configuration.lastProductsUpdate != null)
        brand.configuration.lastProductsUpdate = moment(brand.configuration.lastProductsUpdate.toDate()).toISOString()

      brand = { ...brand, ...yield getBrandImages({ brand }) }

      const { categories = {}, productGroups = {}, ...brandProperties } = brand
      yield put({ type: actions.REDUCE_EDIT_BRAND, payload: brandProperties })
      yield put({ type: actions.REDUCE_EDIT_CATEGORIES, payload: categories })
      yield put({ type: actions.REDUCE_EDIT_PRODUCT_GROUPS, payload: productGroups })
      yield put({ type: actions.GET_STORES, payload: { brandId, storeId, search } })
    }
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
    yield put({ type: actions.REDUCE_EDIT_NAVIGATION, payload: { modality: 'qrReader' } })
  }
  yield put({ type: actions.STOP_LOADING, payload: { action: 'getBrand' } })
}

function * getBrandImages({ brand }) {
  try {
    const backgroundImageUrl = yield firebase.getImageUrl({
      bucketUrl: 'gs://mytec-ecommerce.appspot.com/' + brand.brandId + '/background.png',
    })
    const logoUrl = yield firebase.getImageUrl({
      bucketUrl: 'gs://mytec-ecommerce.appspot.com/' + brand.brandId + '/logo.png',
    })
    return { backgroundImageUrl, logoUrl }
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    newError.code = 103
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
  return {}
}

export default function * brandSagas() {
  yield all([
    watchGetBrand(),
  ])
}
