import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { Popper, Typography, Tooltip, Fab, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Check from '@material-ui/icons/Check'
import Delete from '@material-ui/icons/Delete'
import Clear from '@material-ui/icons/Clear'

import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import Modal from 'components/modal/Modal'
import translator from 'utility/translator'
import { getDeliveryPrice } from 'utility/pureFunctions'

import * as actions from 'redux/actions'

const useStylesDelivery = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
  },
  button: {
    padding: 0,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  buttonContainer: {
    padding: 25,
    display: 'flex',
    justifyContent: 'space-around',
  },
  cpContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
  },
  cpLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  cpPrice: {
    whiteSpace: 'nowrap',
    marginRight: 10,
  },
}))

const DeliveryModal = ({ props: { fields = [], setModal, values = {}, setValues = () => {}, checked = false } }) => {
  const classes = useStylesDelivery()
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const { storeId } = useParams()
  const [errors, setErrors] = useState({})
  const store = state.stores[storeId] || {}

  const price = getDeliveryPrice({ store, cart: state.cart, deliveryMethod: 'delivery' })
  const freeThreshold = ((store.services || {}).delivery || {}).freeThreshold

  const onChange = ({ type, label, value, required }) => {
    if (type === 'zip' && value != null && (store.zipCodes || []).length > 0 && !(store.zipCodes || []).includes(value))
      setErrors({ ...errors, [label]: 'delivery_zipCodeNotServed_error' })
    else if (required && (value === '' || value == null))
      setErrors({ ...errors, [label]: 'delivery_fieldRequired_error' })
    else
      setErrors({ ...errors, [label]: null })

    setValues({ ...values, [label]: value })
  }

  const checkCompletition = () => {
    return fields.filter(({ label, required }) =>
      (required && (values[label] == null || values[label] === '')) || errors[label] != null).length > 0
  }

  const onClose = () => { setModal(null) }

  const onDelete = () => {
    setModal(null)
    setValues({ ...values, ...fields.reduce((list, { label }) => ({ ...list, [label]: '' }), {}) })
    dispatch({ type: actions.DELETE_CART_PRODUCT, payload: { cartProductId: 'deliveryMethod' } })
  }

  const saveAddress = () => {
    dispatch({
      type: actions.CREATE_CART_PRODUCT,
      payload: {
        cartProductId: 'deliveryMethod',
        productId: store.idDeliveryProduct,
        name: 'delivery',
        price,
        quantity: 1,
      },
    })
    setModal(null)
  }

  return (
    <Modal onClose={onClose}>
      <div className={classes.modal}>
        <DeliveryData props={{ price, freeThreshold }} />
        {fields.map(({ label, required, type = 'text' }) => {
          switch (type) {
            case 'zip':
              return (
                <Autocomplete
                  key={label}
                  id={label}
                  value={values[label]}
                  options={store.zipCodes}
                  getOptionLabel={option => option}
                  onChange={(event, value) => onChange({ type, required, label, value })}
                  style={{ width: '100%', marginTop: 10 }}
                  autoHighlight
                  PopperComponent={props => <Popper {...props} style={{ width: 250, zIndex: 4000 }} placement='bottom-start' />}
                  renderInput={({ inputProps, ...params }) => (
                    <TextField
                      {...params}
                      helperText={translator.fromLabel(errors[label]) || ''}
                      error={errors[label] != null}
                      inputProps={{ ...inputProps, 'data-cy': label }}
                      label={translator.fromLabel(`delivery_${label}Textfield_label`)}
                    />
                  )}
                />
              )
            default:
              return (
                <TextField
                  key={label}
                  id={label}
                  inputProps={{ 'data-cy': label }}
                  label={translator.fromLabel(`delivery_${label}Textfield_label`)}
                  value={values[label]}
                  onChange={event => onChange({ type, required, label, value: event.target.value })}
                  onBlur={() => onChange({ type, label, value: values[label], required })}
                  style={{ marginTop: 10 }}
                  type={type}
                  error={errors[label] != null}
                  helperText={translator.fromLabel(errors[label]) || ''}
                  required={required}
                />
              )
          }
        })}
        <div className={classes.buttonContainer}>
          <Tooltip disabled={!checked} title={translator.fromLabel('delivery_delete_button')} placement='top'>
            <Fab onClick={onDelete} className={classes.button} color='secondary' aria-label='cancel'>
              <Delete />
            </Fab>
          </Tooltip>
          <Tooltip title={translator.fromLabel('delivery_close_button')} placement='top'>
            <Fab onClick={onClose} className={classes.button} color='secondary' aria-label='cancel'>
              <Clear />
            </Fab>
          </Tooltip>
          <Tooltip title={translator.fromLabel('delivery_save_button')} placement='top'>
            <div>
              <Fab disabled={checkCompletition()} onClick={saveAddress} className={classes.button} color='primary' aria-label='addToCart'>
                <Check />
              </Fab>
            </div>
          </Tooltip>
        </div>
      </div>
    </Modal>
  )
}

const DeliveryData = ({ props: { price = 0, freeThreshold } }) => {
  return (
    <>
      <Typography variant='h4'>{translator.fromLabel('deliveryModal_title')}</Typography>
      <Typography>{translator.fromLabel('deliveryModal_price') + translator.toPrice(price) + '€'}</Typography>
      {freeThreshold != null
        ? <Typography>{translator.fromLabel('deliveryModal_freeThreshold') + translator.toPrice(freeThreshold) + '€'}</Typography>
        : null}
    </>
  )
}

export default DeliveryModal
