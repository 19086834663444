import React from 'react'

import { Divider, List, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useHistory, useLocation, useParams } from 'react-router-dom'

import translator from 'utility/translator'
import { useSelector } from 'react-redux'

const updateParams = ({ target, value, history, location, pathname }) => {
  let currentUrlParams = new URLSearchParams(location.search)

  if (value !== '')
    currentUrlParams.set(target, value)
  else
    currentUrlParams.delete(target)
  currentUrlParams.delete('productId')

  if (pathname != null)
    history.push({ pathname, search: currentUrlParams.toString() })
  else
    history.push({ search: currentUrlParams.toString() })
}

const useStylesSidebar = makeStyles(theme => ({
  drawer: {
    paddingTop: 20,
  },
  categoryLine: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

const MenuSidebar = ({ props: { setOpenMenu = () => {} } = {} }) => {
  const categories = useSelector(state => state.categories)
  const products = useSelector(state => Object.values(state.products))
  const classes = useStylesSidebar()
  // const [categoryFilter, setCategoryFilter] = useState('')
  const history = useHistory()
  const location = useLocation()
  const { brandId, storeId } = useParams()

  const currentUrlParams = new URLSearchParams(location.search)
  const categoryFilter = currentUrlParams.get('categoryId') || ''

  if (Object.keys(categories || {}).length === 0)
    return null

  return (
    <List>
      <ListItem
        className={classes.categoryLine}
        style={{ fontWeight: 'bold' }}
        aria-label='allCategories'
        data-cy='allCategories_listItem'
        selected={categoryFilter === ''}
        onClick={() => updateParams({ target: 'categoryId', value: '', history, location, pathname: `/${brandId}/${storeId}` })}
      >
        {translator.fromLabel('menu_allCategories_label')}
      </ListItem>
      {Object.values(categories || {})
        .filter(category => products.some(product => product.productGroups.includes(category.productGroupId)))
        .sort((a, b) => (a.position > b.position) ? 1 : -1)
        .map(category => (
          <React.Fragment key={category.categoryId}>
            <Divider />
            <ListItem
              className={classes.categoryLine}
              data-cy={category.categoryId + '_listItem'}
              aria-label={'category ' + category.name}
              selected={categoryFilter === category.categoryId}
              onClick={() => {
                setOpenMenu(false)
                updateParams({
                  target: 'categoryId',
                  value: category.categoryId,
                  history,
                  location,
                  pathname: `/${brandId}/${storeId}`,
                })
              }}
            >
              {category.name || category.categoryId}
            </ListItem>
          </React.Fragment>
        ))}
    </List>
  )
}

export default MenuSidebar
