/* global localStorage */
import { call, put, takeEvery, all, select, race, delay } from 'redux-saga/effects'
import * as actions from 'redux/actions'
import { history } from 'index'

import firebase from 'redux/firebase/firebase'
import { formatOrder, formatError, formatFirebaseError } from 'redux/format'

import { updateProducts } from './productsSagas'

function * watchCreateOrder() {
  yield takeEvery(actions.CREATE_ORDER, createOrder)
}

function * createOrder({ payload: { values = {}, brandId, storeId, mobile = false } }) {
  yield put({ type: actions.START_LOADING, payload: { action: 'createOrder' } })

  try {
    const stores = yield select(state => state.stores)
    const navigation = yield select(state => state.navigation)
    const store = stores[storeId]

    const cartNotModified = yield updateProducts({ payload: { brandId, storeId } })

    if (!cartNotModified && mobile) {
      history.push(`/${brandId}/${storeId}/cart`)
      return false
    }

    const cart = yield select(state => state.cart)

    const order = formatOrder({ brandId, storeId, values, store, cart, navigation })

    if (order.paymentMethod === 'cashOn') {
      const { orderSent } = yield race({
        orderSent: call(sendOrder, { brandId, storeId, order }),
        timeout: delay(120000),
      })

      if (orderSent && navigation.modality === 'orderAtTable')
        yield put({ type: actions.WAIT_ORDER_AT_TABLE, payload: order })
      else if (orderSent)
        yield call(emptyCart, { brandId, storeId })
      else
        throw formatError({ message: 'Network error. Please retry', code: 402 })
    } else {
      order.status = 'unPaid'
      yield put({ type: actions.REDUCE_EDIT_ORDER, payload: order })
      history.push(`/${brandId}/${storeId}/payment`)
    }
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
  yield put({ type: actions.STOP_LOADING, payload: { action: 'createOrder' } })
}

function * sendOrder({ brandId, order }) {
  const response = yield firebase.setSubCollectionDoc({
    collectionId: 'Brands',
    docId: brandId,
    subCollectionId: 'Orders',
    data: order,
    subDocId: String(order.orderId),
  })
  return response
}

function * emptyCart({ brandId, storeId }) {
  yield put({ type: actions.REDUCE_EDIT_CART, payload: {} })
  yield put({ type: actions.REDUCE_EDIT_ORDER, payload: {} })
  localStorage.removeItem('ecommerceBBCart')
  history.push(`/${brandId}/${storeId}/thankYou`)

  return true
}

function * watchCreatePayment() {
  yield takeEvery(actions.CREATE_PAYMENT, createPayment)
}

function * createPayment({ payload: { paymentData = {} } }) {
  yield put({ type: actions.START_LOADING, payload: { action: 'createPayment' } })

  try {
    const navigation = yield select(state => state.navigation)

    const order = {
      ...yield select(state => state.order),
      status: 'toBeSent',
      paymentData,
    }
    const brandId = order.brandId
    const storeId = order.storeId

    const { orderSent } = yield race({
      orderSent: call(sendOrder, { brandId, storeId, order }),
      timeout: delay(120000),
    })

    if (orderSent && navigation.modality === 'orderAtTable')
      yield put({ type: actions.WAIT_ORDER_AT_TABLE, payload: order })
    else if (orderSent)
      yield call(emptyCart, { brandId, storeId })
    else
      throw formatError({ message: 'Network error. Please retry', code: 402 })
  } catch (error) {
    const newError = formatFirebaseError({ firebaseError: error })
    yield put({ type: actions.ERROR, payload: { error: newError } })
  }
  yield put({ type: actions.STOP_LOADING, payload: { action: 'createPayment' } })
}

export default function * orderSagas() {
  yield all([
    watchCreateOrder(),
    watchCreatePayment(),
  ])
}
