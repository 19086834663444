import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Home from 'views/home/Home'
import StoreSelector from 'views/storeSelector/StoreSelector'
import ProductSelector from 'views/productSelector/ProductSelector'
import Product from 'views/product/Product'
import Cart from 'views/cart/Cart'
import Order from 'views/order/Order'
import ThankYou from 'views/thankYou/ThankYou'
import WelcomeModal from 'views/welcomeModal/WelcomeModal'
import Payment from 'views/payment/Payment'
import OrderAtTableWarning from 'views/orderAtTableWarning/OrderAtTableWarning.jsx'
import WaitOrder from 'views/waitOrder/WaitOrder.jsx'

import BrandHeader from './components/BrandHeader'
import MobileBrandHeader from './components/MobileBrandHeader'
import DesktopHeader from './components/DesktopHeader'
import MobileMenu from './components/MobileMenu'
import DesktopMenu from './components/DesktopMenu'

import MosquitoNet from 'components/mosquitoNet/MosquitoNet'

import { useSelector } from 'react-redux'

import { useSize } from 'components/sizeSwitcher/SizeSwitcher'

import { Route } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  mobileContainer: {
    padding: theme.spacing(2),
    margin: 'auto',
  },
  desktopContainer: {
    padding: theme.spacing(0, 2),
    margin: 'auto',
  },
  gridContainer: {
    paddingTop: 30,
  },
  gridItem: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0px 10px',
    position: 'relative',
  },
}))

const ContentManager = () => {
  const classes = useStyles()
  const brand = useSelector(state => state.brand)
  const navigation = useSelector(state => state.navigation)
  const { mobile, height } = useSize()

  const containerHeight = height -
    (brand.backgroundImageUrl != null && brand.logoUrl != null ? 200 : 100) -
    (navigation.modality === 'orderAtTable' ? 32 : 0)

  if (mobile)
    return (
      <>
        {navigation.modality === 'orderAtTable'
          ? <Route path='/:brandId/:storeId?/:view?'><OrderAtTableWarning /></Route>
          : null}
        <Route path='/:brandId/:storeId?/:view?'><MobileMenu /></Route>
        <Route exact path='/:brandId/:storeId?'><MobileBrandHeader /></Route>
        <Route path='/:brandId/:storeId'><WelcomeModal /></Route>
        <div className={classes.mobileContainer}>
          <Route exact path='/'><Home /></Route>
          <Route exact path='/:brandId'><StoreSelector /></Route>
          <Route exact path='/:brandId/:storeId'><ProductSelector /></Route>
          <Route exact path='/:brandId/:storeId/products'><Product /></Route>
          <Route exact path='/:brandId/:storeId/cart'><Cart /></Route>
          <Route exact path='/:brandId/:storeId/order'><Order /></Route>
          <Route exact path='/:brandId/:storeId/payment'><Payment /></Route>
          <Route exact path='/:brandId/:storeId/thankYou'><ThankYou /></Route>
          <Route exact path='/:brandId/:storeId/waitOrder'><WaitOrder /></Route>
        </div>
      </>
    )

  else
    return (
      <>
        {navigation.modality === 'orderAtTable'
          ? <Route path='/:brandId/:storeId?/:view?'><OrderAtTableWarning /></Route>
          : null}
        <Route path='/:brandId/:storeId?'><DesktopHeader /></Route>
        <Route path='/:brandId/:storeId'><WelcomeModal /></Route>
        <div className={classes.desktopContainer}>
          <Grid
            container
            className={classes.gridContainer}
            style={{ height: containerHeight }}
          >
            <Grid item xs={3} className={classes.gridItem}>
              <Route path={['/:brandId/:storeId/payment', '/:brandId/:storeId/waitOrder']}><MosquitoNet /></Route>
              <Route path='/:brandId/:storeId/:view?'><DesktopMenu /></Route>
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <Route path='/:brandId/:storeId/:view?'><BrandHeader /></Route>
              <Route exact path='/'><Home /></Route>
              <Route exact path='/:brandId'><StoreSelector /></Route>
              <Route exact path='/:brandId/:storeId'><ProductSelector /></Route>
              <Route exact path='/:brandId/:storeId/products'><Product /></Route>
              <Route exact path='/:brandId/:storeId/order'><Order /></Route>
              <Route exact path='/:brandId/:storeId/payment'><Payment /></Route>
              <Route exact path='/:brandId/:storeId/thankYou'><ThankYou /></Route>
              <Route exact path='/:brandId/:storeId/waitOrder'><WaitOrder /></Route>
            </Grid>
            <Grid item xs={3} className={classes.gridItem}>
              <Route path={['/:brandId/:storeId/payment', '/:brandId/:storeId/waitOrder']}><MosquitoNet /></Route>
              <Route path='/:brandId/:storeId/:view?'><Cart /></Route>
            </Grid>
          </Grid>
        </div>
      </>
    )
}

export default ContentManager
