import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { Button, Divider, List, ListItem, Typography, Tooltip } from '@material-ui/core'

import RemoveShoppingCart from '@material-ui/icons/RemoveShoppingCart'

import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import CartProduct from './components/CartProduct'
import Footer from './components/Footer'
import { getTotalPrice } from 'utility/pureFunctions'

import translator from 'utility/translator'
import * as actions from 'redux/actions'
import { useSize } from 'components/sizeSwitcher/SizeSwitcher'

const useStyles = makeStyles((theme) => ({
  cartContainer: {
    width: '100%',
    maxWidth: theme.values.componentsMaxWidth,
    margin: 'auto',
    paddingBottom: 50,
  },
  divider: {
    marginTop: 20,
  },
  fab1: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deliveryMethodPrice: {
    paddingTop: 10,
  },
}))

const Cart = () => {
  const stores = useSelector(state => state.stores)
  const cart = useSelector(state => state.cart)
  const loading = useSelector(state => state.loading)
  const history = useHistory()
  const [totalPrice, setTotalPrice] = useState(0)
  const { brandId, storeId, view } = useParams()
  const dispatch = useDispatch()
  const [buttonDisabled, setButtonDisabled] = useState(null)
  const store = stores[storeId] || {}
  const { mobile } = useSize()
  const navigation = useSelector(state => state.navigation)

  const goToOrder = () => {
    if (!mobile)
      dispatch({
        type: actions.UPDATE_PRODUCTS,
        payload: { brandId, storeId },
      })
    history.push(`/${brandId}/${storeId}/order`)
  }

  useEffect(() => {
    if (mobile && Object.keys(cart || {}).length === 0)
      history.push(`/${brandId}/${storeId}`)
  }, [mobile, brandId, cart, history, storeId])

  useEffect(() => {
    if (Object.keys(cart || {}).length > 0)
      setTotalPrice(getTotalPrice({ cart }))
    else
      setTotalPrice(0)
  }, [cart, stores, storeId])

  useEffect(() => {
    const buttonDisabledString = `${translator.fromLabel('cart_beneathMinOrder_warning')} ${store.minOrder}€`

    if (view === 'order' && navigation.modality !== 'orderAtTable' && typeof store?.minOrder === 'number' && totalPrice < store.minOrder) {
      if (buttonDisabled !== buttonDisabledString)
        setButtonDisabled(buttonDisabledString)
    } else if (loading.length > 0 || view === 'order' || view === 'payment' || Object.values(cart || {}).filter(cartProduct => cartProduct.cartProductId !== 'deliveryMethod').length === 0) {
      if (buttonDisabled !== '')
        setButtonDisabled('')
    } else if (navigation.modality !== 'orderAtTable' && typeof store.minOrder === 'number' && totalPrice < store.minOrder) {
      if (buttonDisabled !== buttonDisabledString)
        setButtonDisabled(buttonDisabledString)
    } else if (buttonDisabled != null) {
      setButtonDisabled(null)
    }
  }, [buttonDisabled, setButtonDisabled, totalPrice, store, cart, view, loading])

  const clearCart = () => {
    dispatch({ type: actions.DELETE_CART, payload: {} })
    dispatch({ type: actions.GO_HOME, payload: { brandId, storeId } })
  }

  const disableButtons = view === 'waitOrder'

  if (Object.keys(stores || {}).length === 0)
    return null

  return (
    <div id='cart'>
      <TitleBar props={{ clearCart, buttonDisabled }} />
      <CartList props={{ cart, mobile, disableButtons }} />
      <Footer props={{ totalPrice, buttonDisabled, goToOrder, mobile, view }} />
    </div>
  )
}

const TitleBar = ({ props: { clearCart, buttonDisabled } }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.title}>
        <Typography variant='h4'>{translator.fromLabel('cart_title')}</Typography>
        <Tooltip title={translator.fromLabel('cart_clear_button')} placement='top'>
          <div style={{ width: 'fit-content' }}>
            <Button variant='outlined' disabled={buttonDisabled === ''} onClick={clearCart} aria-label='clear'>
              <RemoveShoppingCart />
            </Button>
          </div>
        </Tooltip>
      </div>
    </>
  )
}

const CartList = ({ props: { cart, mobile, disableButtons = false } }) => {
  const classes = useStyles()
  const deliveryMethodPrice = (cart.deliveryMethod || {}).price || 0

  const cartProductsList = Object.values(cart || {})
    .filter(({ productId, cartProductId }) => productId != null && cartProductId !== 'deliveryMethod')
    .sort((a, b) => a.waitingTime !== b.waitingTime
      ? a.waitingTime > b.waitingTime ? 1 : -1
      : a.name > b.name ? 1 : -1)

  return (
    <List className={classes.cartContainer} id='cartProductsList'>
      <Divider className={classes.divider} />
      {cartProductsList.map(cartProduct => (
        <React.Fragment key={cartProduct.cartProductId}>
          <ListItem disableGutters={!mobile}>
            <CartProduct props={{ cartProduct, mobile, disableButtons }} />
          </ListItem>
          <Divider className={classes.divider} />
        </React.Fragment>
      ))}
      {deliveryMethodPrice
        ? (
          <div className={classes.deliveryMethodPrice}>
            <Typography variant='h6'>{translator.fromLabel('cart_deliveryPrice') + ((cart.deliveryMethod || {}).price || 0) + '€'}</Typography>
            <Divider className={classes.divider} />
          </div>
          ) : null}
    </List>
  )
}

export default Cart
